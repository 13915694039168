import { render, staticRenderFns } from "./ProductTlcLandView.html?vue&type=template&id=19ca9a84&scoped=true&external"
import script from "./ProductTlcLandView.vue?vue&type=script&lang=ts"
export * from "./ProductTlcLandView.vue?vue&type=script&lang=ts"
import style0 from "./ProductTlcLandView.scss?vue&type=style&index=0&id=19ca9a84&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "19ca9a84",
  null
  
)

export default component.exports