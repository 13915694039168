














































































































import {Component, InjectReactive, Prop, Vue} from 'vue-property-decorator';
import {mixins} from "vue-class-component";
import ComponentBase from "@/components/ComponentBase";
import NewsListData from "@/views/News/NewsList/NewsListData";

@Component({
  name: 'NewsList'
})
export default class NewsList extends mixins(ComponentBase) {
  @InjectReactive() isMobile!: boolean;

  //선택한 페이지 인덱스, 현재 페이지 인덱스
  curPage = 1

  //한 페이지 인덱스, 다음 누르면 +1, 이전 누르면 -1
  curPagePagingIdx = 0

  //news 데이터
  newsListData = NewsListData

  //현재 보여지는 페이지 네비게이션 배열
  pageNavigationIdx: number[][] = []

  get curPageContentList() {
    const start = (this.curPage - 1) * 12;
    const end = start + 12;
    return NewsListData.slice(start, end);
  }

  //다음 버튼
  curPagePagingNext() {

    //반올림
    const maxPagingIdx = Math.ceil(NewsListData.length / 12)

    //다음 버튼 클릭 시 페깅의 첫 인덱스로 고정
    this.curPage = this.pageNavigationIdx[this.curPagePagingIdx][0] + 4

    //다음 버튼 누를 시 마지막 페이지면 마지막 페이지로 고정
    if (this.curPage > maxPagingIdx) {
      this.curPage = maxPagingIdx;
      return ''
    }

    //페깅 인덱스 ++
    else {
      this.curPagePagingIdx = this.curPagePagingIdx + 1
    }

  }

  //이전 버튼
  curPagePagingPrev() {

    //이전 버튼 클릭 시 페깅의 첫 인덱스로 고정
    this.curPage = this.pageNavigationIdx[this.curPagePagingIdx][0] - 4

    //이전 버튼 누를 시 첫 페이지면 첫 페이지로 고정
    if (this.curPage < 1) {
      this.curPage = 1;
      return ''
    }

    //페깅 인덱스 --
    else {
      this.curPagePagingIdx = this.curPagePagingIdx - 1
    }

    if (this.curPagePagingIdx < 0) {
      return ''
    }

  }

  //처음으로 버튼
  curPagePagingStart() {
    this.curPagePagingIdx = 0
    this.curPage = 1
  }

  //마지막으로 버튼
  curPagePagingEnd() {
    this.curPagePagingIdx = this.pageNavigationIdx.length - 1
    this.curPage = Math.ceil(NewsListData.length / 12)
  }


  mounted() {

    const maxPagingIdx = Math.ceil(NewsListData.length / 12)

    const pagingList: number[] = []

    for (let i = 1; i <= maxPagingIdx; i++) {
      pagingList.push(i);

    }

    for (let i = 0; i < pagingList.length; i += 4) {
      this.pageNavigationIdx.push(pagingList.slice(i, i + 4));
    }

  }



}
