
import {Component, InjectReactive, Prop, Vue, Watch} from 'vue-property-decorator';
import {mixins} from "vue-class-component";
import ComponentBase from "@/components/ComponentBase";

@Component({
    name: 'ServiceBroadsiteLive'
})
export default class ServiceBroadsiteLive extends mixins(ComponentBase) {
  @InjectReactive() isMobile!: boolean;

  mounted() {
    this.initSwiper();
  }

  @Watch('en')
  onEnChange() {
    setTimeout(() => {
      this.initSwiper();
    }, 300);
  }

  @Watch('isMobile')
  onIsMobileChange() {
    setTimeout(() => {
      this.initSwiper();
    }, 300);
  }

  initSwiper() {
    if(this.isMobile) {
      // @ts-ignore
      const swiper = new window.Swiper('.swiper-container', {
        slidesPerView: 1,
        allowTouchMove: false,
        loop: false,
        autoplay: {
          delay: 3000
        },
        pagination: {
          el: '.m_sub-swiper-pagination',
          type: 'fraction',
        },
        navigation: {
          nextEl: '.m_sub-button-next',
          prevEl: '.m_sub-button-prev',
        },
      });
    } else {
      // @ts-ignore
      const swiper = new window.Swiper('.swiper-container', {
        slidesPerView: 1,
        allowTouchMove: false,
        loop: false,
        autoplay: {
          delay: 3000
        },
        pagination: {
          el: '.sub-swiper-pagination',
          type: 'fraction',
        },
        navigation: {
          nextEl: '.sub-button-next',
          prevEl: '.sub-button-prev',
        },
      });
    }

  }
}
