import { render, staticRenderFns } from "./ProductTlcSmart.html?vue&type=template&id=24d991c4&scoped=true&external"
import script from "./ProductTlcSmart.vue?vue&type=script&lang=ts"
export * from "./ProductTlcSmart.vue?vue&type=script&lang=ts"
import style0 from "./ProductTlcSmart.scss?vue&type=style&index=0&id=24d991c4&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24d991c4",
  null
  
)

export default component.exports