



























































































































































































































import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
    name: 'PlanPrivacyPolicyContent_Latest'
})
export default class PlanPrivacyPolicyContent_Latest extends Vue {

}
