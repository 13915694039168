
import {Component, InjectReactive, Prop, Vue} from 'vue-property-decorator';
import {mixins} from "vue-class-component";
import ComponentBase from "@/components/ComponentBase";

@Component({
    name: 'TechMultipathAggregation'
})
export default class TechMultipathAggregation extends mixins(ComponentBase) {
  @InjectReactive() isMobile!: boolean;
}
