
import 'reflect-metadata';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
    name: 'PlanTerms'
})
export default class PlanTerms extends Vue {

}
