




































































































































































































































































// import 'reflect-metadata';
import {Component, InjectReactive, Prop, Vue, Watch} from 'vue-property-decorator';
import Header from "@/components/Header/Header.vue";
import Footer from "@/components/Footer/Footer.vue";
import {mixins} from "vue-class-component";
import ComponentBase from "@/components/ComponentBase";
import router from "@/router";
import UseInquiriesButton from "@/components/UseInquiriesButton/UseInquiriesButton.vue";

@Component({
  name: 'Main',
  methods: {
    router() {
      return router
    }
  },
  components: {Header, Footer, UseInquiriesButton}
})
export default class Main extends mixins(ComponentBase) {
  @InjectReactive() scrollTop!: number;
  @InjectReactive() isMobile!: boolean;

  swiper = null;

  mounted() {
    // this.initSwiper();
  }

  //@Watch('isMobile')
  //onIsMobileChange() {
  //  setTimeout(() => {
  //    this.initSwiper();
  //  }, 500);
  //}

  initSwiper() {
    if (!this.isMobile) {
      // @ts-ignore
      this.swiper = new window.Swiper('.swiper-container', {
        slidesPerView: 1,
        allowTouchMove: false,
        loop: false,
        autoplay: {
          delay: 5000,
          disableOnInteraction: false,
        },
        pagination: {
          el: '.swiper-pagination',
          type: 'fraction',
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      });
    } else {
      // @ts-ignore
      this.swiper = new window.Swiper('.m_swiper-container', {
        slidesPerView: 1,
        allowTouchMove: false,
        loop: true,
        autoplay: {
          delay: 5000,
          disableOnInteraction: false,
        },
        pagination: {
          el: '.m_swiper-pagination',
          type: 'fraction',
        },
        navigation: {
          nextEl: '.m_swiper-button-next',
          prevEl: '.m_swiper-button-prev',
        },
      });
    }

  }

  @Watch('scrollTop')
  onScrollTopChange() {
    // @ts-ignore
    // const $ = window.$;
    // $(".box").each(function() {
    //   // @ts-ignore
    //   if($(window).scrollTop() >= $(this).offset().top - 800) {
    //     // @ts-ignore
    //     $(this).addClass("show");
    //   } else {
    //     // @ts-ignore
    //     $(this).removeClass("show");
    //   }
    // });
    //
    // $(".ip_convert_img").each(function() {
    //   // @ts-ignore
    //   if($(window).scrollTop() >= $(this).offset().top - 800) {
    //     // @ts-ignore
    //     $(this).addClass("show");
    //   } else {
    //     // @ts-ignore
    //     $(this).removeClass("show");
    //   }
    // });
  }

}
