





























































































import {Component, InjectReactive, Prop, Vue} from 'vue-property-decorator';
import {mixins} from "vue-class-component";
import ComponentBase from "@/components/ComponentBase";
import AboutBanner from "@/components/AboutBanner/AboutBanner.vue";
@Component({
  name: 'AboutAdaptiveUplinkStreaming', components: {AboutBanner}
})
export default class AboutAdaptiveUplinkStreaming extends mixins(ComponentBase) {
  @InjectReactive() isMobile!: boolean;
}
