
import 'reflect-metadata';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
    name: 'Terms'
})
export default class Terms extends Vue {

}
