
import {Component, InjectReactive, Prop, Vue} from 'vue-property-decorator';
import {mixins} from "vue-class-component";
import ComponentBase from "@/components/ComponentBase";
import ServerRequesting from "@/components/ServerRequesting/ServerRequesting.vue";

@Component({
  name: 'ContactMain',
  components: {ServerRequesting}
})
export default class ContactMain extends mixins(ComponentBase) {
  @InjectReactive() isMobile!: boolean;

  name = '';
  phone = '';
  email = '';
  title = '';
  content = '';

  agree = 'on';

  requesting = false;

  requestingMsg = '';

  send() {
    if(this.requesting) {
      alert('요청중입니다. 잠시만 기다려 주세요.');
      return;
    }

    if(this.name.trim() === '') {
      alert('이름을 입력해 주세요.');
      return;
    }
    if(this.email.trim() === '') {
      alert('이메일을 입력해 주세요.');
      return;
    }
    if(this.phone.trim() === '') {
      alert('전화번호를 입력해 주세요.');
      return;
    }

    if(this.agree != 'on') {
      alert('개인정보 수집/이용에 동의해주세요.');
      return;
    }

    this.requestingMsg = '접수중입니다. 잠시만 기다려주세요.';
    this.requesting = true;

    //
    // @ts-ignore
    const $ = window.$;
    $.post('/contact_request.php', {name: this.name, email: this.email, phone: this.phone, title: this.title, content: this.content}, (res: any) => {
      setTimeout(() => {
        this.requesting = false;
      }, 2000);
      if(res === 'ok') {
        this.requestingMsg = '접수되었습니다.';

        this.name = '';
        this.phone = '';
        this.email = '';
        this.title = '';
        this.content = '';

      } else if(res === '이메일 주소를 정확하게 입력해주세요.') {
        this.requestingMsg = res;
      } else {
        this.requestingMsg = '다시 시도해 주세요.';
      }
    });

  }
}
