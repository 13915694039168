













































































































































































































import {Component, InjectReactive, Prop, Vue} from 'vue-property-decorator';
import {mixins} from "vue-class-component";
import ComponentBase from "@/components/ComponentBase";
import BusinessButton from "@/components/BusinessButton/BusinessButton.vue";

@Component({
  name: 'ProductOverview', components: {BusinessButton}
})
export default class ProductOverview extends mixins(ComponentBase) {
  @InjectReactive() isMobile!: boolean;

}
