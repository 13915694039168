






























































































































































































































































import { Component, Vue } from 'vue-property-decorator';

@Component({
    name: 'PlanPrivacyPolicyContent_Old1'
})
export default class PlanPrivacyPolicyContent_Old1 extends Vue {

}
