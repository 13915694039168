






























import {Component, Prop, Emit, ProvideReactive, Ref, Vue} from "vue-property-decorator";

@Component({ name: "SolutionLink",  components: {}})
export default class SolutionLink extends Vue {
  @Prop() nowPage!: string
}
