const NewsListData = [
    {
        "title": "SKT, 2024 국제안전보건전시 ‘AI 활용 안전기술’ 선봬",
        "link": "https://m.etoday.co.kr/view.php?idxno=2375254",
        "thumbnail": '/contents/images/news/thumb/news_thumb_0200.jpeg',
        "tags": [
            "#SK텔레콤",
            "#국제안전보건전시회",
            "#AI"
        ],
        "writeTime": "2024.07.01 10:45"
    },
    {
        "title": "똑똑한 드론...구조 현장 효자 노릇",
        "link": "https://n.news.naver.com/article/655/0000018898",
        "thumbnail": '/contents/images/news/thumb/news_thumb_0199.jpeg',
        "tags": [
            "#충북 소방",
            "#드론",
            "#드론 수색"
        ],
        "writeTime": "2024.06.05 21:10"
    },
    {
        "title": "드론영상시스템에 AI분석 연말 접목…대형 사회재난 효과적 대응",
        "link": "https://n.news.naver.com/article/030/0003209453",
        "thumbnail": '/contents/images/news/thumb/news_thumb_0198.jpeg',
        "tags": [
            "#충남도",
            "#드론",
            "#사회재난 피해 저감 사업"
        ],
        "writeTime": "2024.05.28 07:17"
    },
    {
        "title": "재난상황 대응, 드론으로 효율성 높여",
        "link": "https://news.knn.co.kr/news/article/157652?fbclid=IwZXh0bgNhZW0CMTEAAR2b0wzQLI-c6ulJVhokUPP-wH3B3MJHtvgeE3ifK3Ty-PDxhondHpaIm0c_aem_WR4GoveyA0t6LAgQLybVRw",
        "thumbnail": '/contents/images/news/thumb/news_thumb_0197.jpeg',
        "tags": [
            "#경상남도",
            "#영상관제 시스템",
            "#드론"
        ],
        "writeTime": "2024.05.24 20:49"
    },
    {
        "title": "어디서든 실시간으로!📷",
        "link": "https://youtu.be/Mppt1AWp9tI?feature=shared",
        "thumbnail": '/contents/images/news/thumb/news_thumb_0196.jpeg',
        "tags": [
            "#경상남도",
            "#박완수도지사",
            "#드론"
        ],
        "writeTime": "2024.05.24 13:07"
    },
    {
        "title": "경남 경상남도 산불 등 드론으로 실시간 재난 상황 감시",
        "link": "https://youtu.be/v-bOSddsosg?feature=shared",
        "thumbnail": '/contents/images/news/thumb/news_thumb_0195.jpeg',
        "tags": [
            "#재난",
            "#드론",
            "#산불"
        ],
        "writeTime": "2024.05.24 11:20"
    },
    {
        "title": "드론 살펴보는 군 관계자들",
        "link": "https://n.news.naver.com/article/003/0012512659",
        "thumbnail": '/contents/images/news/thumb/news_thumb_0194.jpeg',
        "tags": [
            "#세종시 32사단",
            "#드론",
            "#통합 방위훈련"
        ],
        "writeTime": "2024.04.25 14:00"
    },
    {
        "title": "\"울주군 드론 정책 배우자\" 벤치마킹 줄잇는다",
        "link": "https://n.news.naver.com/article/003/0012509388",
        "thumbnail": '/contents/images/news/thumb/news_thumb_0193.jpeg',
        "tags": [
            "#울주군",
            "#드론 표준도시",
            "#2024 드론쇼코리아"
        ],
        "writeTime": "2024.04.24 09:54"
    },
    {
        "title": "드론·인공지능·ICT…산불관리, 스마트 기술로 한다",
        "link": "https://n.news.naver.com/article/003/0012518409",
        "thumbnail": '/contents/images/news/thumb/news_thumb_0192.jpeg',
        "tags": [
            "#광주 북구",
            "#한국전력",
            "#스마트빌리지"
        ],
        "writeTime": "2024.04.29 13:06"
    },
    {
        "title": "포팩트, SKT와 ‘LTE 실시간 바디캠’ 협업 진행",
        "link": "https://n.news.naver.com/article/025/0003355229",
        "thumbnail": '/contents/images/news/thumb/news_thumb_0191.jpeg',
        "tags": [
            "#포팩트",
            "#SK텔레콤",
            "#바디캠"
        ],
        "writeTime": "2024.04.19 14:27"
    },
    {
        "title": "'선택2024' MBC 간판 앵커·기자 총출동‥4인 4색 토론 ‘총선데스크’",
        "link": "https://m.entertain.naver.com/article/408/0000219929?fbclid=IwZXh0bgNhZW0CMTEAAR0YFgWsp1lB5ieRPuP2AAOdijKEkYfaPPFnMmU9ahtC1kb1HhwnOzNBtdI_aem_zOIKZ7yqsigtfUvpfJkr0Q",
        "thumbnail": '/contents/images/news/thumb/news_thumb_0190.jpeg',
        "tags": [
            "#제22대 국회의원선거",
            "#선택 2024",
            "#XR"
        ],
        "writeTime": "2024.04.08 10:01"
    },
    {
        "title": "충남도, 도·시군 합동 재난·재해 대응 드론 훈련",
        "link": "https://www.ccdailynews.com/news/articleView.html?idxno=2260839&fbclid=IwZXh0bgNhZW0CMTEAAR3yPdfeynTa89oS2SfJOxKQbauyo_KGCX2FRDRP-NBlAKcuVwsy-OXFVtE_aem_WRa835cq_AkqpmO3y8Y8IQ",
        "thumbnail": '/contents/images/news/thumb/news_thumb_0189.jpeg',
        "tags": [
            "#충남도",
            "#드론 훈련",
            "#드론 통합관제센터"
        ],
        "writeTime": "2024.03.06 16:39"
    },
    {
        "title": "드론으로 재난 통합관리...경남도, 드론 영상관제 시스템 구축",
        "link": "https://n.news.naver.com/article/023/0003824783",
        "thumbnail": '/contents/images/news/thumb/news_thumb_0188.jpg',
        "tags": [
            "#경남도",
            "#드론",
            "#재난안전상황실"
        ],
        "writeTime": "2024.03.27 15:11"
    },
    {
        "title": "아시아매미나방 알아보는 똑똑한 탐지기",
        "link": "http://m.youngnong.co.kr/news/articleView.html?idxno=46114&fbclid=IwZXh0bgNhZW0CMTEAAR3QV_X9-pG69WD2r8xoBblQEK9BSyKMn4iHWJXGcG91sR49DdaoVDvyVFQ_aem_4s5o6KjWl0ay5gAZpk8wwA",
        "thumbnail": '/contents/images/news/thumb/news_thumb_0187.jpeg',
        "tags": [
            "#국제식물검역인증원",
            "#매미나방",
            "#SK텔레콤"
        ],
        "writeTime": "2024.03.19 11:20"
    },
    {
        "title": "Korea drone exhibition: Event showcases military and industrial models",
        "link": "https://youtu.be/bqwnJJowwAI?feature=shared",
        "thumbnail": '/contents/images/news/thumb/news_thumb_0186.jpeg',
        "tags": [
            "#드론",
            "#군용 무인기",
            "#2024 드론쇼코리아"
        ],
        "writeTime": "2024.03.08 12:00"
    },
    {
        "title": "이통사 '드론쇼' 한자리···LG유플은 UAM 실증 데이터 최초 공개",
        "link": "https://m.etnews.com/20240307000273",
        "thumbnail": '/contents/images/news/thumb/news_thumb_0185.jpeg',
        "tags": [
            "#2024 드론쇼코리아",
            "#도심항공교통(UAM)",
            "#T라이브 캐스터"
        ],
        "writeTime": "2024.03.07 14:36"
    },
    {
        "title": "KOMSA, ‘여객선 안전운항관리 모니터링 시스템’ 본격 가동",
        "link": "https://n.news.naver.com/article/082/0001255905",
        "thumbnail": '/contents/images/news/thumb/news_thumb_0184.jpeg',
        "tags": [
            "#수중 드론",
            "#내일의 운항예보",
            "#힌국해양교통안전공단"
        ],
        "writeTime": "2024.02.16 11:19"
    },
    {
        "title": "서울 시내 산불 AI로 실시간 감지···수락산에 시범 구축",
        "link": "https://n.news.naver.com/article/032/0003276818",
        "thumbnail": '/contents/images/news/thumb/news_thumb_0183.jpeg',
        "tags": [
            "#수락산",
            "#지능형 산불방지 ICT(정보통신기술)",
            "#서울시"
        ],
        "writeTime": "2024.02.01 13:18"
    },
    {
        "title": "예산군, 각종 행정업무에 드론 적극 활용",
        "link": "https://www.asiatoday.co.kr/view.php?key=20240123010013784&t=1720576137",
        "thumbnail": '/contents/images/news/thumb/news_thumb_0182.jpeg',
        "tags": [
            "#예산군",
            "#4차산업",
            "#드론"
        ],
        "writeTime": "2024.01.23 11:23"
    },
    {
        "title": "스포츠 넘어 안전·교통분야까지… 지자체 ‘드론사업 삼매경’ [CES 2024]",
        "link": "https://n.news.naver.com/article/022/0003892590",
        "thumbnail": '/contents/images/news/thumb/news_thumb_0181.jpeg',
        "tags": [
            "#드론 특별자유화구역",
            "#산불 진화 중화물 드론",
            "#드론 UAM 엑스포"
        ],
        "writeTime": "2024.01.08 18:04"
    },
    {
        "title": "강원도 '인공지능(AI) 앰뷸런스 사업' 참여",
        "link": "https://www.dailymedi.com/news/news_view.php?wr_id=898124&fbclid=IwAR1OHt4GNJskwUsBdq0BvOqRxSOtGvik755G9GU9FInPfIwfWatdcUR_iQ0",
        "thumbnail": '/contents/images/news/thumb/news_thumb_0180.jpeg',
        "tags": [
            "#원주의료원",
            "#AI 앰뷸런스",
            "#원주소방서"
        ],
        "writeTime": "2023.05.26 10:30"
    },
    {
        "title": "국제식물검역인증원, 2023년 아시아매미나방 특별검역기간 운영",
        "link": "https://m.idsn.co.kr/news/view/1065579936370522",
        "thumbnail": '/contents/images/news/thumb/news_thumb_0179.jpeg',
        "tags": [
            "#국제식물검역인증원",
            "#아시아매미나방",
            "#매미나방 탐지용 드론"
        ],
        "writeTime": "2023.05.28 14:24"
    },
    {
        "title": "울산, 드론실증도시 구축 우수 지자체 선정",
        "link": "https://www.ulsanpress.net/news/articleView.html?idxno=517083&fbclid=IwAR0XGtK5U5dzWCzuTc2Mo0nWcn9kQVUbi5Kn5DjJJLaF_QyHfxHCqg-_dY8",
        "thumbnail": '/contents/images/news/thumb/news_thumb_0178.jpeg',
        "tags": [
            "#드론실증도시",
            "#국토교통부",
            "#드론"
        ],
        "writeTime": "2023.11.28 20:10"
    },
    {
        "title": "수자원공사 군위댐지사, ‘K-드론시스템 실증지원사업 시연회’ 개최",
        "link": "http://www.kyongbuk.co.kr/news/articleView.html?idxno=2147906&fbclid=IwAR3s0QlmmVOpsUZlTzyefslsgRErN22NmasIlRIE3mN1CZXvfWvLIRdlABI",
        "thumbnail": '/contents/images/news/thumb/news_thumb_0177.jpeg',
        "tags": [
            "#실증지원사업",
            "#수자원공사",
            "#드론"
        ],
        "writeTime": "2023.11.21 20:06"
    },
    {
        "title": "“목표 발견!”, T라이브 캐스터 드론 관제의 뛰어난 실력 인정 받다",
        "link": "https://news.sktelecom.com/199754",
        "thumbnail": '/contents/images/news/thumb/news_thumb_0176.png',
        "tags": [
            "#TLiveCaster",
            "#SK텔레콤",
            "#드론"
        ],
        "writeTime": "2023.11.17 13:12"
    },
    {
        "title": "구조자 수색에 '드론' 뜬다 울산 맞춤형 드론 231115",
        "link": "https://youtu.be/ChjcbUAnwQk?feature=shared",
        "thumbnail": '/contents/images/news/thumb/news_thumb_0175.jpeg',
        "tags": [
            "#울산시",
            "#드론 실증도시",
            "#산악구조용 드론"
        ],
        "writeTime": "2023.11.16 11:00"
    },
    {
        "title": '방호장비 배송·방사능 감시…“드론으로”',
        "link": "https://n.news.naver.com/article/056/0011603230",
        "thumbnail": '/contents/images/news/thumb/news_thumb_0174.jpeg',
        "tags": [
            "#울산시",
            "#인공지능 관제 시스템",
            "#산악구조용 드론"
        ],
        "writeTime": "2023.11.15 11:25"
    },
    {
        "title": '최명수 전남도의원, "소방차 진입 사각지 해소 위해 드론 도입 확대해야"',
        "link": "https://n.news.naver.com/article/079/0003831390",
        "thumbnail": '/contents/images/news/thumb/news_thumb_0173.jpeg',
        "tags": [
            "#전라남도의회",
            "#전남소방본부",
            "#드론"
        ],
        "writeTime": "2023.11.08 11:32"
    },
    {
        "title": "충남도, 스마트국토엑스포 참가...드론통합관제센터 등 소개",
        "link": "https://n.news.naver.com/article/629/0000247407",
        "thumbnail": '/contents/images/news/thumb/news_thumb_0172.jpeg',
        "tags": [
            "#충남도",
            "#스마트국토엑스포",
            "#드론통합관제센터"
        ],
        "writeTime": "2023.11.06 10:48"
    },
    {
        "title": '"못가는 곳은 없다" 육상과 해상을 넘나드는 소방드론 인명구조 활약',
        "link": "https://n.news.naver.com/article/654/0000056503",
        "thumbnail": '/contents/images/news/thumb/news_thumb_0171.jpeg',
        "tags": [
            "#강원도소방본부",
            "#소방드론",
            "#SK텔레콤"
        ],
        "writeTime": "2023.11.02 10:20"
    },
    {
        "title": "곡성소방서, 제1회 드론 경진대회 ‘장려상’",
        "link": "https://www.newscj.com/news/articleView.html?idxno=3076264",
        "thumbnail": '/contents/images/news/thumb/news_thumb_0170.jpeg',
        "tags": [
            "#곡성소방서",
            "#드론 경진대회",
            "#SK텔레콤"
        ],
        "writeTime": "2023.10.27 12:16"
    },
    {
        "title": "SK텔레콤의 드론을 이용한 통합관제솔루션 기술",
        "link": "https://n.news.naver.com/article/030/0003143861",
        "thumbnail": '/contents/images/news/thumb/news_thumb_0169.jpeg',
        "tags": [
            "#과학기술정보통신부",
            "#2023 AIoT 국제전시회",
            "#SK텔레콤"
        ],
        "writeTime": "2023.10.11 14:38"
    },
    {
        "title": "강서구에 드론 개발 지원 시설…내년 말 완공",
        "link": "https://n.news.naver.com/article/056/0011578771",
        "thumbnail": '/contents/images/news/thumb/news_thumb_0168.jpeg',
        "tags": [
            "#강서구",
            "#드론",
            "#SK텔레콤"
        ],
        "writeTime": "2023.10.10 10:05"
    },
    {
        "title": "[현안 돋보기] 김천, 드론산업 거점 꿈꾸다",
        "link": "https://news.kbs.co.kr/news/mobile/view/view.do?ncd=7776611&fbclid=IwAR3ZwEMbE4zjS-JTthiDXr0pPHgdMnhcQsKuFrXj3hNrKUQns4RsKWLXpaI",
        "thumbnail": '/contents/images/news/thumb/news_thumb_0167.jpeg',
        "tags": [
            "#김천",
            "#드론",
            "#SK텔레콤"
        ],
        "writeTime": "2023.09.18 19:32"
    },
    {
        "title": "전남소방, 도내 소방서·특수대응단에 '소방드론' 보급 완료",
        "link": "https://n.news.naver.com/article/277/0005312019",
        "thumbnail": '/contents/images/news/thumb/news_thumb_0166.jpeg',
        "tags": [
            "#전남소방본부",
            "#소방드론",
            "#SK텔레콤"
        ],
        "writeTime": "2023.09.10 21:38"
    },
    {
        "title": "2023 MOTUL KMRC Round-4 Live Stream",
        "link": "https://www.youtube.com/live/R70ckLsS_hI?feature=shared",
        "thumbnail": '/contents/images/news/thumb/news_thumb_0165.jpeg',
        "tags": [
            "#KMRC",
            "#KIC F1",
            "#미니모토"
        ],
        "writeTime": "2023.09.10 18:21"
    },
    {
        "title": "울주군, 드론특별자유화구역 2차 착수",
        "link": "https://www.enewstoday.co.kr/news/articleView.html?idxno=2034162",
        "thumbnail": '/contents/images/news/thumb/news_thumb_0164.jpeg',
        "tags": [
            "#울주군",
            "#드론",
            "#드론특구"
        ],
        "writeTime": "2023.09.01 15:00"
    },
    {
        "title": "충남도, 군 작전·재난 시 드론 영상 실시간 공유",
        "link": "https://www.ccdailynews.com/news/articleView.html?idxno=2223444&fbclid=IwAR3yNPYT--FrY_axU3tyqmHaCZ3Onz2QMih72d0C68gsBClw5PL0cUKUE2c",
        "thumbnail": '/contents/images/news/thumb/news_thumb_0163.jpeg',
        "tags": [
            "#충남도",
            "#드론",
            "#을지연습"
        ],
        "writeTime": "2023.08.22 11:18"
    },
    {
        "title": "예산군, 드론 활용 폭염 예찰 활동 실시",
        "link": "https://www.ccdailynews.com/news/articleView.html?idxno=2222062&fbclid=IwAR2qDFP547p8BesjyeE5rW-P5nQARlsKbkrxZlpTYQKchmIEOvssf85v4iU",
        "thumbnail": '/contents/images/news/thumb/news_thumb_0162.jpeg',
        "tags": [
            "#예산군",
            "#드론",
            "#폭염특보"
        ],
        "writeTime": "2023.08.14 16:45"
    },
    {
        "title": "고흥군, 국방부장관배 드론봇챌린지 대회 예선전 개최",
        "link": "http://www.siminsori.com/news/articleView.html?idxno=243914&fbclid=IwAR2rXumevYRUHbLgsMcwWSJ_AoV8BQ0tYeb2ZzQuyWqQ_EPmNInWyEWy5hs",
        "thumbnail": '/contents/images/news/thumb/news_thumb_0161.jpeg',
        "tags": [
            "#고흥군",
            "#국방부장관배 드론봇챌린지",
            "#드론"
        ],
        "writeTime": "2023.08.09 15:12"
    },
    {
        "title": "드론으로 잔불 감시·대피 방송까지",
        "link": "https://news.kbs.co.kr/news/pc/view/view.do?ncd=7733818&ref=A&fbclid=IwAR3v5J_wAINKYk8ibnOGOJlosjXekyTqqx9AZ10l6b7wEiom0SHz7Tj8CoA",
        "thumbnail": '/contents/images/news/thumb/news_thumb_0160.jpeg',
        "tags": [
            "#SK텔레콤",
            "#재난 대응",
            "#드론"
        ],
        "writeTime": "2023.07.26 23:35"
    },
    {
        "title": "드론 활용한 재난 대처 '바로영상통합관제'",
        "link": "https://youtu.be/wajgDBjUlxU?feature=shared",
        "thumbnail": '/contents/images/news/thumb/news_thumb_0159.jpeg',
        "tags": [
            "#SK텔레콤",
            "#울산",
            "#드론"
        ],
        "writeTime": "2023.07.26 11:31"
    },
    {
        "title": "현실이 된 영화 속 로봇! AI 로보틱스 TOP3🤖(Feat. 이장원)",
        "link": "https://youtu.be/PvvvPRQcJ4c?feature=shared",
        "thumbnail": '/contents/images/news/thumb/news_thumb_0158.jpeg',
        "tags": [
            "#SK텔레콤",
            "#이장원",
            "#AI로보틱스"
        ],
        "writeTime": "2023.07.24 15:00"
    },
    {
        "title": "능력 만렙! 👍개 멋진 AI 로봇견을 만나다 🐶 이렇게! #Shorts",
        "link": "https://youtube.com/shorts/UuyyHK4nXWc?feature=shared",
        "thumbnail": '/contents/images/news/thumb/news_thumb_0157.jpeg',
        "tags": [
            "#로봇개",
            "#robotics",
            "#Tlivecaster"
        ],
        "writeTime": "2023.07.24 12:00"
    },
    {
        "title": "입고 걷는 로봇부터 경비·커피까지...로봇은 이제 생필품",
        "link": "https://www.youtube.com/watch?v=AsEIEhRVels",
        "thumbnail": '/contents/images/news/thumb/news_thumb_0156.jpeg',
        "tags": [
            "#로봇개",
            "#드론",
            "#Tlivecaster"
        ],
        "writeTime": "2023.07.10 15:06"
    },
    {
        "title": "근로자 보호하는 ICT 기술의 진화···SKT 안전솔루션 [현장+]",
        "link": "https://www.thereport.co.kr/news/articleView.html?idxno=37694&fbclid=IwAR0QIWx9CHNO5N2J9YGNyCGSN1ZRRhlJQ8OUNfhqckVSYJ0BQuPv7JgKd0g",
        "thumbnail": '/contents/images/news/thumb/news_thumb_0155.jpeg',
        "tags": [
            "#국제안전보건전시회",
            "#비전 AI",
            "#Tlivecaster"
        ],
        "writeTime": "2023.07.07 11:00"
    },
    {
        "title": "비전 AI, 지능형 안전, 스마트 모니터링 분야 총 11개의 혁신 아이템을 전시",
        "link": "https://www.linkedin.com/posts/sk-telecom_skt-kiss2023-ai-activity-7081894963986251776-1EyK/?fbclid=IwAR3XCyw3eyh7MxzvLDuw1XRpPz5eU1_w6sUGgQwlP9B-cOXPCYiP_obOnBQ",
        "thumbnail": '/contents/images/news/thumb/news_thumb_0154.jpeg',
        "tags": [
            "#비전 AI",
            "#로봇개",
            "#Tlivecaster"
        ],
        "writeTime": "2023.07.07 08:20"
    },
    {
        "title": "코세코와 함께하는 DJI 신제품 발표 세미나",
        "link": "https://www.youtube.com/live/rg8pVX64Z9I?feature=share&t=1130&fbclid=IwAR2UrpXNlgTtcWJ1Moj9xhPtB-cchThvGcs_whGxSot7saaA0o-0n-QHTnA",
        "thumbnail": '/contents/images/news/thumb/news_thumb_0153.jpeg',
        "tags": [
            "#코세코",
            "#DJI",
            "#Tlivecaster"
        ],
        "writeTime": "2023.07.06 13:30"
    },
    {
        "title": "SKT, 2023 국제안전보건전시회 참가... “ICT로 좀 더 안전한 산업현장 만드는 데 기여한다!”",
        "link": "https://kr.aving.net/news/articleView.html?idxno=1781132&fbclid=IwAR1rcbgipGkEZBQqA1VvDugPFha520cORhkmFiVYGIo8g3tmw_0vowRsdFc",
        "thumbnail": '/contents/images/news/thumb/news_thumb_0152.jpeg',
        "tags": [
            "#국제안전보건전시회",
            "#Vision AI",
            "#Tlivecaster"
        ],
        "writeTime": "2023.07.03 14:21"
    },
    {
        "title": "\"실종자 수색에 성과\"…경찰 드론기지 35곳으로 확대",
        "link": "https://n.news.naver.com/article/001/0014028102?sid=102&fbclid=IwAR3K5_8TdWrdEnM6Z9SC59zdXcbJJmjrPESXAbxs8CPpcXPPKF2hfDjHvv8",
        "thumbnail": '/contents/images/news/thumb/news_thumb_0151.jpeg',
        "tags": [
            "#서울 경찰서",
            "#경찰 드론 거점 관서",
            "#Tlivecaster"
        ],
        "writeTime": "2023.06.27 12:00"
    },
    {
        "title": "울주군, 무인이동체 엑스포서 방사능 측정 드론 선보여",
        "link": "https://n.news.naver.com/article/421/0006880020?sid=102&fbclid=IwAR1cmQOUCdeLv8U_fwwkC1ykg1lWRIjPVsDFWm90GSMQfhxtOqW7etrD7kM",
        "thumbnail": '/contents/images/news/thumb/news_thumb_0150.jpeg',
        "tags": [
            "#울주군",
            "#서울 코엑스",
            "#Tlivecaster"
        ],
        "writeTime": "2023.06.21 15:28"
    },
    {
        "title": "확 변신 중인 ‘신천 수변공원’",
        "link": "http://www.kbmaeil.com/news/articleView.html?idxno=961307&fbclid=IwAR36kQZmgUcA7iuvVV4Egrp0ciZaCsga2VoZp_MJk5P7KqbgEEBFvl39w6c",
        "thumbnail": '/contents/images/news/thumb/news_thumb_0149.jpeg',
        "tags": [
            "#신천 수변공원",
            "#로봇개",
            "#Tlivecaster"
        ],
        "writeTime": "2023.06.18 19:50"
    },
    {
        "title": "서부발전, 전기산업엑스포 참가…디지털 혁신 기술 선보여",
        "link": "https://n.news.naver.com/article/030/0003107709?sid=004&fbclid=IwAR1XoILQTFaFuazCUxM-xOMD13dYXjLma3-F8XiZrXrqZJCuLVJYd5VCxU4",
        "thumbnail": '/contents/images/news/thumb/news_thumb_0148.png',
        "tags": [
            "#서부발전",
            "#전기산업엑스포",
            "#Tlivecaster"
        ],
        "writeTime": "2023.06.14 14:25"
    },
    {
        "title": "울주군, 드론특별자유화구역 2차 업무 협약 체결",
        "link": "https://m.metroseoul.co.kr/article/20230601500535?fbclid=IwAR3E6oSKM5fS18yeaOaSSznqCClJJJpJgbqw07T-Lrm8srRWRvqPuC_cBjw",
        "thumbnail": '/contents/images/news/thumb/news_thumb_0147.png',
        "tags": [
            "#드론",
            "#을주군",
            "#Tlivecaster"
        ],
        "writeTime": "2023.06.01 15:38"
    },
    {
        "title": "“현장중심 활동으로 도민 안전 챙기겠다”",
        "link": "http://www.gnnews.co.kr/news/articleView.html?idxno=528551",
        "thumbnail": '/contents/images/news/thumb/news_thumb_0146.png',
        "tags": [
            "#드론",
            "#박완수 경남지사",
            "#Tlivecaster"
        ],
        "writeTime": "2023.05.09 17:26"
    },
    {
        "title": "충남도, 화랑훈련서 드론으로 훈련 작전 지원",
        "link": "https://daily.hankooki.com/news/articleView.html?idxno=961998",
        "thumbnail": '/contents/images/news/thumb/news_thumb_0145.png',
        "tags": [
            "#화량훈련",
            "#드론",
            "#Tlivecaster"
        ],
        "writeTime": "2023.05.29 10:16"
    },
    {
        "title": "박완수 경남지사， 교량ㆍ재해지구 점검",
        "link": "http://www.gnmaeil.com/news/articleView.html?idxno=518428",
        "thumbnail": '/contents/images/news/thumb/news_thumb_0144.png',
        "tags": [
            "#박완수 경남지사",
            "#교량ㆍ재해지구",
            "#Tlivecaster"
        ],
        "writeTime": "2023.05.09 20:39"
    },
    {
        "title": "입고 걷는 로봇부터 경비·커피까지...로봇은 이제 생필품",
        "link": "https://n.news.naver.com/article/052/0001879262",
        "thumbnail": '/contents/images/news/thumb/news_thumb_0143.png',
        "tags": [
            "#AI로봇키트",
            "#로봇",
            "#Tlivecaster"
        ],
        "writeTime": "2023.04.30 11:37"
    },
    {
        "title": "[기획특집] 김천시, 미래먹거리 '드론산업' 지역거점 구축에 전력 투구",
        "link": "http://www.kbsm.net/news/view.php?idx=388037",
        "thumbnail": '/contents/images/news/thumb/news_thumb_0142.png',
        "tags": [
            "#김천시",
            "#드론산업",
            "#Tlivecaster"
        ],
        "writeTime": "2023.04.17 13:27"
    },
    {
        "title": "울산시, 재난안전 ‘드론실증도시’ 뽑혀… 15개 지자체 협력",
        "link": "https://n.news.naver.com/article/277/0005236940",
        "thumbnail": '/contents/images/news/thumb/news_thumb_0141.png',
        "tags": [
            "#울산시",
            "#드론실증도시",
            "#Tlivecaster"
        ],
        "writeTime": "2023.03.28 08:27"
    },
    {
        "title": "울산·창원, ‘드론 실증도시’ 됐다",
        "link": "https://n.news.naver.com/article/658/0000035756",
        "thumbnail": '/contents/images/news/thumb/news_thumb_0140.png',
        "tags": [
            "#울산·창원",
            "#드론",
            "#Tlivecaster",
        ],
        "writeTime": "2023.03.19 08:28"
    },
    {
        "title": "도미노피자, 대구 수성못에서 드론·로봇 배송 선보인다",
        "link": "https://n.news.naver.com/article/030/0003084345",
        "thumbnail": '/contents/images/news/thumb/news_thumb_0139.png',
        "tags": [
            "#도미노피자",
            "#로봇개",
            "#Tlivecaster"
        ],
        "writeTime": "2023.03.15 09:46"
    },
    {
        "title": "“로봇으로 화재 ∙ 가스누출 감지”, 드론쇼 코리아 2023 ‘AI 로봇키트’ 공개 현장",
        "link": "https://news.sktelecom.com/185407",
        "thumbnail": '/contents/images/news/thumb/news_thumb_0138.png',
        "tags": [
            "#AI로봇키트",
            "#로봇개",
            "#Tlivecaster"
        ],
        "writeTime": "2023.02.24 11:40"
    },
    {
        "title": "SKT, 로봇에 부착해 화재 및 가스누출 감시하는 ‘AI로봇키트’ 출시",
        "link": "https://news.sktelecom.com/185292",
        "thumbnail": "/contents/images/news/thumb/news_thumb_0137.png",
        "tags": [
            "#AI로봇키트",
            "#로봇개",
            "#T라이브캐스터"
        ],
        "writeTime": "2023.02.20 09:00"
    },
    {
        "title": "SK텔레콤 뉴스룸",
        "link": "https://www.facebook.com/sktelecom.newsroom/videos/665071701868489",
        "thumbnail": "/contents/images/news/thumb/news_thumb_0136.png",
        "tags": [
            "#무인항공기",
            "#T라이브캐스터"
        ],
        "writeTime": "2022.12.07 15:00"
    },
    {
        "title": "진천소방서, 재난 현장 드론 활용 ‘라이브시스템’",
        "link": "https://m.fpn119.co.kr/187550?fbclid=IwAR13zUAVSSVCHHTiyzvQXIS3ahOrgZzotr2VrA_Jb4x9RKckrKYsGrj02LU",
        "thumbnail": "/contents/images/news/thumb/news_thumb_0135.png",
        "tags": [
            "#진천 소방서",
            "#재난현장",
            "#T라이브캐스터"
        ],
        "writeTime": "2022.11.25 11:17"
    },
    {
        "title": "“가스 유출 비상!” 5G 연결된 로봇개 · 드론이 출동한다",
        "link": "https://news.sktelecom.com/183148?fbclid=IwAR3-Tg8D3Ln2ZzIGQAerRfQQqmUZTka--P8F9ZKbAxOHyhvC5ec3pktql00",
        "thumbnail": "/contents/images/news/thumb/news_thumb_0134.png",
        "tags": [
            "#로봇개",
            "#SK텔레콤",
            "#T라이브캐스터"
        ],
        "writeTime": "2022.11.18 16:10"
    },
    {
        "title": "서부발전, 안전한국훈련으로 재난대응 '이상 무'",
        "link": "http://m.boannews.com/html/detail.html?idx=111683&tab_type=1&fbclid=IwAR0XmllKR_55XfUfSiUwsIwWUamqsNAUBxenbnlfWTQi--zL2ZGYxlSd3k8",
        "thumbnail": "/contents/images/news/thumb/news_thumb_0133.png",
        "tags": [
            "#서부발전",
            "#행정안전",
            "#T라이브캐스터"
        ],
        "writeTime": "2022.11.17 11:01"
    },
    {
        "title": "울산시, '제1회 드론·측량 박람회' 개최…\"지자체 최초\"",
        "link": "https://n.news.naver.com/article/001/0013464482?sid=102&fbclid=IwAR1WweSiwyr4bAsIa2BYz-mApAGtKw__r1m09OaI8Gc4o0_6-ylnVLvQ_BM",
        "thumbnail": "/contents/images/news/thumb/news_thumb_0132.png",
        "tags": [
            "#울산",
            "#TLCSmart",
            "#T라이브캐스터"
        ],
        "writeTime": "2022.10.25 11:17"
    },
    {
        "title": "자치경찰단, 드론 순찰 역할 '톡톡'...드론 활용 현장대응력 강화 - 국제뉴스",
        "link": "https://www.gukjenews.com/news/articleView.html?idxno=2563583&fbclid=IwAR3gJ0qXhLyO4d72_Bx1L9g5hLeZAN_5NcYF4NI9X2juqZotKqK0Cw8kvp4",
        "thumbnail": "/contents/images/news/thumb/news_thumb_0131.png",
        "tags": [
            "#제주도 자치 경찰",
            "#동국 대학교",
            "#T라이브캐스터"
        ],
        "writeTime": "2022.10.22 10:00"
    },
    {
        "title": "소방청, 미래 재난대응 선도 '제2회 드론경진대회' 개최 - 국토 일보",
        "link": "http://www.ikld.kr/news/articleView.html?idxno=262100&fbclid=IwAR1i4M4kPF43DR2q02on0hu9X2FNyQCq-LVAEisZuQ0J5KWXO2z3fLDRowY",
        "thumbnail": "/contents/images/news/thumb/news_thumb_0130.png",
        "tags": [
            "#소방청",
            "#4차 산업",
            "#T라이브캐스터"
        ],
        "writeTime": "2022.10.22 09:16"
    },
    {
        "title": "부여군, 폭우 피해 현장 조사에 드론 투입",
        "link": "http://www.goodmorningcc.com/news/articleView.html?idxno=275012&fbclid=IwAR3N-2_m2GDeg1qF3lpWt4hoMN44zLEE715qpBlBx-cUctHnWaTHAIh0Tik",
        "thumbnail": "/contents/images/news/thumb/news_thumb_0129.png",
        "tags": [
            "#부여군",
            "#TLCSmart",
            "#T라이브캐스터"
        ],
        "writeTime": "2022.08.25 11:17"
    },
    {
        "title": "청양군, 수해 현장에 드론 투입…신속 복구 도와",
        "link": "http://www.ccdn.co.kr/news/articleView.html?idxno=776511&fbclid=IwAR2krp7jtrbshZHyWIFmhfK6bwgN3p78I9C3H7ZV7CU-YMWZSDXri__MGWg#09SX",
        "thumbnail": "/contents/images/news/thumb/news_thumb_0128.png",
        "tags": [
            "#청양군",
            "#TLCSmart",
            "#T라이브캐스터"
        ],
        "writeTime": "2022.08.24 19:02"
    },
    {
        "title": "충남 공무원 드론 조종대회서 논산시·태안군 최우수상 수상",
        "link": "https://n.news.naver.com/article/629/0000162276?sid=102&fbclid=IwAR2zGq45EpUDjKiBtUF_dJYd58EkPiZEmbD6VZ8z5atXZTzMGWf2WuM1yR0",
        "thumbnail": "/contents/images/news/thumb/news_thumb_0127.png",
        "tags": [
            "#충남 공무원",
            "#드론",
            "#TLCSmart",
            "#T라이브캐스터"
        ],
        "writeTime": "2022.07.21 14:00"
    },
    {
        "title": "인적 오류마저 예방한다…서부발전 디지털 안전기술 '눈길'",
        "link": "https://www.electimes.com/news/articleView.html?idxno=305148&fbclid=IwAR2qPMunWVQqlMFQDXjdw52E9xBSDxC7wZr9eomWabgL1Llgl8sYH3orL-c",
        "thumbnail": "/contents/images/news/thumb/news_thumb_0126.png",
        "tags": [
            "#서부발전"
        ],
        "writeTime": "2022.07.18 17:43"
    },
    {
        "title": "[드론·UAM박람회] 드론 실증에도 '안전'이 최우선",
        "link": "http://www.kidd.co.kr/news/228452?fbclid=IwAR3PokSUZZ3g16wJTlDNGOVO4H0w3zELEFNLqZu1g0JeGyPM2eDlqspPTSI",
        "thumbnail": "/contents/images/news/thumb/news_thumb_0125.png",
        "tags": [
            "#UAM",
            "#드론",
            "#TLCSmart",
            "#T라이브캐스터"
        ],
        "writeTime": "2022.07.17 10:36"
    },
    {
        "title": "울주군, ‘드론·UAM 박람회’ 홍보부스 운영",
        "link": "http://www.enewstoday.co.kr/news/articleView.html?idxno=1584070&fbclid=IwAR0zp1zA1qPs06hKURW2Rer7CqgmNaYit1sEFQSBRFuYpUg2GDsPKlaUPsg",
        "thumbnail": "/contents/images/news/thumb/news_thumb_0124.png",
        "tags": [
            "#UAM",
            "#울주군",
            "#TLCSmart",
            "#T라이브캐스터"
        ],
        "writeTime": "2022.07.15 15:30"
    },
    {
        "title": "서천군, 드론을 활용한 업무협업 긍정적",
        "link": "http://www.daejonilbo.com/news/articleView.html?idxno=2010721&fbclid=IwAR05Ow7nSEYgzKreh8M6HIlvLTLo9o1Wp1u2bxwjOVW9Rz6M2b1l52lz_QY",
        "thumbnail": "/contents/images/news/thumb/news_thumb_0123.png",
        "tags": [
            "#자율주행 순찰로봇",
            "#서천",
            "#TLCSmart",
            "#T라이브캐스터"
        ],
        "writeTime": "2022.06.26 15:00"
    },
    {
        "title": "드론·로봇배송 성큼…디지털 물류 실증사업 발표회 26일 개최",
        "link": "https://n.news.naver.com/article/092/0002259883?sid=105&fbclid=IwAR1oBjUBgsI-6KBlpViyuMCGGSRY709rwV2mTmL51JFRtCjb3td0iYOSz0o",
        "thumbnail": "/contents/images/news/thumb/news_thumb_0122.png",
        "tags": [
            "#자율주행 순찰로봇",
            "#관악구",
            "#TLCSmart",
            "#T라이브캐스터"
        ],
        "writeTime": "2022.06.16 17:43"
    },
    {
        "title": "[2022년 전기산업엑스포] 발전현장 안전관리 트렌드는 무엇?",
        "link": "https://www.electimes.com/news/articleView.html?idxno=303292&fbclid=IwAR1ctliif-DTKuEIMzeIE-cDBe7iW3qBmgGQrEXLn3c685Dc9faAeXYhAt4",
        "thumbnail": "/contents/images/news/thumb/news_thumb_0121.png",
        "tags": [
            "#한국서부발전",
            "#스마트모바일상황보고시스템",
            "#T라이브캐스터",
            "#TLCSmart"
        ],
        "writeTime": "2022.05.19 16:20"
    },
    {
        "title": "서부발전, 재난대비 상시훈련 시행",
        "link": "http://www.ikld.kr/news/articleView.html?idxno=236988&fbclid=IwAR1r_kWWoQNw-grExyLSPe_ZjHGLizcdZh8tgdGbbNi1Z5Ra2OMsnN8fXBc",
        "thumbnail": "/contents/images/news/thumb/news_thumb_0120.png",
        "tags": [
            "#한국서부발전",
            "#스마트모바일상황보고시스템",
            "#T라이브캐스터",
            "#TLCSmart"
        ],
        "writeTime": "2022.05.19 16:00"
    },
    {
        "title": "전국 최초 도심형 자율주행 순찰로봇 운영 개시",
        "link": "https://www.youtube.com/watch?v=3yMGZAFsR1U",
        "thumbnail": "/contents/images/news/thumb/news_thumb_0119.png",
        "tags": [
            "#자율주행순찰로봇",
            "#로봇영상관제시스템",
            "#T라이브캐스터",
            "#TLCSmart"
        ],
        "writeTime": "2022.05.06 11:00"
    },
    {
        "title": "드론·로봇배송 성큼…디지털 물류 실증사업 발표회 26일 개최",
        "link": "https://n.news.naver.com/article/008/0004737229?lfrom=facebook&fbclid=IwAR0-GUVAdQe8QUqO_dqZ1AgOoLUM4yYLQMIMzZ7BykDyOB6to_xnqsTGQnw",
        "thumbnail": "/contents/images/news/thumb/news_thumb_0118.png",
        "tags": [
            "#드론실증도시",
            "#항공안전기술원",
            "#드론영상관제시스템",
            "#T라이브캐스터"
        ],
        "writeTime": "2022.04.25 11:00"
    },
    {
        "title": "2022 부산국제보트쇼 생방송 / KIMAWEEK 해양레저의 메카 부산 20220423",
        "link": "https://www.youtube.com/watch?t=1839&fbclid=IwAR1blQC8GWLftB_3RGE0Unw5mfgmVnYLxLsetimNkH_aUzU4H_aRxRy8rtI&v=BjJSIZty2Cc&feature=youtu.be",
        "thumbnail": "/contents/images/news/thumb/news_thumb_0117.png",
        "tags": [
            "#부산국제모터쇼",
            "#페기물수거탱크",
            "#드론영상관제시스템",
            "#스카이엔터프라이즈"
        ],
        "writeTime": "2022.04.24 08:33"
    },
    {
        "title": "고양시, 드론산업 날개 펼친다",
        "link": "https://www.boannews.com/media/view.asp?idx=106172&fbclid=IwAR3w94IwfO7hfss9pS0I5mV0ES6F2dSr-D7q9h-5EQzRm1ukPQ8kwpKMOpQ",
        "thumbnail": "/contents/images/news/thumb/news_thumb_0116.png",
        "tags": [
            "#고양시",
            "#드론앵커센터",
            "#SK텔레콤",
            "#T라이브캐스터"
        ],
        "writeTime": "2022.04.20 09:59"
    },
    {
        "title": "대구시, K-드론시스템 실증사업 선정",
        "link": "https://n.news.naver.com/article/030/0003010818?lfrom=facebook&fbclid=IwAR3w94IwfO7hfss9pS0I5mV0ES6F2dSr-D7q9h-5EQzRm1ukPQ8kwpKMOpQ",
        "thumbnail": "/contents/images/news/thumb/news_thumb_0115.png",
        "tags": [
            "#K드론시스템",
            "#대구시",
            "#한국수자원공사",
            "#스카이엔터프라이즈"
        ],
        "writeTime": "2022.04.18 08:56"
    },
    {
        "title": "제주도 자치경찰단, 드론 수색으로 실종된 고사리 채취객 구조",
        "link": "https://n.news.naver.com/article/031/0000666425?lfrom=facebook&fbclid=IwAR2VrJSIo6n7BjvOOWwVfy_Slq5txftefJfSu9WUI13wudicx33s3Sj0IY0",
        "thumbnail": "/contents/images/news/thumb/news_thumb_0114.png",
        "tags": [
            "#제주자치경찰단",
            "#EVO2PRO",
            "#드론영상관제",
            "#TLCSmartDroneAE"
        ],
        "writeTime": "2022.04.15 09:00"
    },
    {
        "title": "자율주행 순찰로봇, 1인가구 많은 우리 관악에 딱이야 [현장 행정]",
        "link": "https://n.news.naver.com/article/081/0003264149?lfrom=facebook&fbclid=IwAR2VrJSIo6n7BjvOOWwVfy_Slq5txftefJfSu9WUI13wudicx33s3Sj0IY0",
        "thumbnail": "/contents/images/news/thumb/news_thumb_0113.png",
        "tags": [
            "#관악구",
            "#자율주행순찰로봇",
            "#로봇영상관제시스템",
            "#T라이브캐스터"
        ],
        "writeTime": "2022.04.08 05:08"
    },
    {
        "title": "태안군, 재난 현장 등 `드론 영상 실시간 중계시스템`",
        "link": "https://tjmbc.co.kr/article/ESuxBfZ7JS28?fbclid=IwAR3ivVU4R60NiVxKCeOif7Z4gI8N9_kvr_JhEMLlJpEzq3_y2FA1XJtQSLg",
        "thumbnail": "/contents/images/news/thumb/news_thumb_0112.png",
        "tags": [
            "#태안군",
            "#드론영상실시간중계시스템",
            "#T라이브캐스터"
        ],
        "writeTime": "2022.03.29 07:00"
    },
    {
        "title": "관악구, 스마트경로당 등 주민 생활밀착 스마트도시 서비스 도입",
        "link": "https://www.onews.tv/news/articleView.html?idxno=120768&fbclid=IwAR0zLgK0R0CTaU0bC8_ugwljjhU0GP6Ufxizz9O7PMRnA3Qk6q4YOTGhgEI",
        "thumbnail": "/contents/images/news/thumb/news_thumb_0111.png",
        "tags": [
            "#관악구청",
            "#자율주행",
            "#순찰로봇",
            "#로봇영상관제시스템"
        ],
        "writeTime": "2022.04.06 08:21"
    },
    {
        "title": "태안군, 재난 현장 등 '드론 영상 실시간 중계시스템' 운영",
        "link": "https://n.news.naver.com/mnews/article/001/0013075963?sid=102&lfrom=facebook&fbclid=IwAR3GM72srgC8tlVhfNmXS9vyOjXUJo-ptqHaVi2hzc5EVf7juOmegoCEsng",
        "thumbnail": "/contents/images/news/thumb/news_thumb_0110.png",
        "tags": [
            "#태안군",
            "#드론영상관제시스템",
            "#Tlivestudio",
            "#TLCSmartDrone"
        ],
        "writeTime": "2022.03.28. 09:34 "
    },
    {
        "title": "울진 산불, 소방헬기·드론 활약상 ‘톡톡’",
        "link": "http://www.dkilbo.com/news/articleView.html?idxno=359955&fbclid=IwAR2YhbFe9JSRs9rxFR7GmK3U5JJUxKWOakYuXZeIlWuueWlevaYAP-zgfQQ",
        "thumbnail": "/contents/images/news/thumb/news_thumb_0109.png",
        "tags": [
            "#대구소방안전본부",
            "#드론영상관제",
            "#T라이브캐스터",
            "#TLCSmartDrone"
        ],
        "writeTime": "2022.03.21 19:46"
    },
    {
        "title": "김천시, 국토부 '드론 실증 지원도시' 선정…13억 확보",
        "link": "http://www.kyongbuk.co.kr/news/articleView.html?idxno=2096708",
        "thumbnail": "/contents/images/news/thumb/news_thumb_0108.png",
        "tags": [
            "#드론실증도시",
            "#항공안전기술원",
            "#드론영상관제시스템",
            "#T라이브캐스터"
        ],
        "writeTime": "2022.03.15 18:27"
    },
    {
        "title": "태안군, 드론으로 해양쓰레기 찾는다",
        "link": "http://m.joongdo.co.kr/view.php?key=20220303010000615",
        "thumbnail": "/contents/images/news/thumb/news_thumb_0107.png",
        "tags": [
            "#태안군청",
            "#드론영상관제시스템",
            "#T라이브캐스터",
            "#Tlivestudio"
        ],
        "writeTime": "2022.03.03 14:37"
    },
    {
        "title": "SKT, 5G 기반 드론 영상관제 솔루션 공개",
        "link": "https://m.inews24.com/v/1455186",
        "thumbnail": "/contents/images/news/thumb/news_thumb_0106.png",
        "tags": [
            "#드론쇼코리아",
            "#드론영상관제시스템",
            "#T라이브캐스터",
            "#Tlivestudio"
        ],
        "writeTime": "2022.02.25 09:33"
    },
    {
        "title": "'드론에서 앱까지' 옥천·보은소방서 톡톡 튀는 화재 예방",
        "link": "https://n.news.naver.com/mnews/article/421/0005884359?sid=102&lfrom=facebook",
        "thumbnail": "/contents/images/news/thumb/news_thumb_0105.png",
        "tags": [
            "#옥천소방서",
            "#충북소방본부",
            "#드론영상관제시스템",
            "#TLCSmartDrone"
        ],
        "writeTime": "2022.02.05 07:00"
    },
    {
        "title": "예산군, 드론 영상 실시간 중계시스템 활용 확대",
        "link": "https://www.asiatoday.co.kr/view.php?key=20220209010003835",
        "thumbnail": "/contents/images/news/thumb/news_thumb_0104.png",
        "tags": [
            "#예산군",
            "#드론영상관제시스템",
            "#T라이브캐스터",
            "#TLCSmartDrone",
            "#Tlivestudio"
        ],
        "writeTime": "2022.02.18 14:00"
    },
    {
        "title": "양구소방서, 소방드론 시연회",
        "link": "http://m.fpn119.co.kr/170764",
        "thumbnail": "/contents/images/news/thumb/news_thumb_0103.png",
        "tags": [
            "#양구소방서",
            "#강원도소방본부",
            "#드론영상관제시스템",
            "#T라이브캐스터"
        ],
        "writeTime": "2022.01.06 14:00"
    },
    // {
    //     "title": "양구소방서, 소방드론 시연회",
    //     "link": "http://m.fpn119.co.kr/170764",
    //     "thumbnail": "/contents/images/news/thumb/news_thumb_0103.png",
    //     "tags": [
    //         "#양구소방서",
    //         "#강원도소방본부",
    //         "#드론영상관제시스템",
    //         "#T라이브캐스터"
    //     ],
    //     "writeTime": "2022.01.06 14:00"
    // },
    {
        "title": "재난현장 누비는 '소방 드론'",
        "link": "https://www.tbc.co.kr/news/view?c1=8news&c2&pno=20211217155435AE00497&id=166394",
        "thumbnail": "/contents/images/news/thumb/news_thumb_0102.png",
        "tags": [
            "#대구소방본부",
            "#드론영상관제시스템",
            "#T라이브캐스터",
            "#TLCSmartDrone"
        ],
        "writeTime": "2022.01.05 19:00"
    },
    {
        "title": "'자율주행 순찰로봇' 등 4개 혁신기술, 규제샌드박스 적용",
        "link": "https://www.yna.co.kr/view/AKR20220102019600003?input=fb",
        "thumbnail": "/contents/images/news/thumb/news_thumb_0101.png",
        "tags": [
            "#진천소방서",
            "#충북소방본부",
            "#드론영상관제",
            "#T라이브캐스터"
        ],
        "writeTime": "2022.01.03 06:00"
    },
    {
        "title": "증평소방서,소방드론 운용 특별구조훈련",
        "link": "https://www.ccdailynews.com/news/articleView.html?idxno=2103812",
        "thumbnail": "/contents/images/news/thumb/news_thumb_0100.png",
        "tags": [
            "#증평소방",
            "#충북소방본부",
            "#소방드",
            "#드론영상관제"
        ],
        "writeTime": "2021.12.31 09:19"
    },
    {
        "title": "너도나도 실증사업, 현대차·한화·롯데 UAM 경쟁 격화",
        "link": "http://it.chosun.com/site/data/html_dir/2021/11/17/2021111702050.html?outlink=facebook",
        "thumbnail": "/contents/images/news/thumb/news_thumb_0099.png",
        "tags": [
            "#UAM",
            "#드론텍시",
            "#이동형영상관제",
            "#T라이브캐스터"
        ],
        "writeTime": "2021.12.30 06:00"
    },
    {
        "title": "“서비스냐 기체 개발 먼저냐”…유통·통신사도 뛰어든 에어택시",
        "link": "https://n.news.naver.com/article/025/0003155642?lfrom=facebook",
        "thumbnail": "/contents/images/news/thumb/news_thumb_0098.png",
        "tags": [
            "#UAM",
            "#실시간영상관제",
            "#TLCSmart",
            "#T라이브캐스터"
        ],
        "writeTime": "2021.12.29 4:42"
    },
    {
        "title": "도심항공교통 대규모 비행실증 본격 추진…2023년부터 개시",
        "link": "https://news.kbs.co.kr/news/view.do?ncd=5359202",
        "thumbnail": "/contents/images/news/thumb/news_thumb_0097.png",
        "tags": [
            "#국토교통부",
            "#UAM팀코리아",
            "#비대면간담회",
            "#비대면생방"
        ],
        "writeTime": "2021.12.29 20:38"
    },
    {
        "title": "출범 2주년 '5·18 진상규명조사위' 27일 대국민 보고회…내용은?",
        "link": "https://m.news1.kr/articles/?4534698",
        "thumbnail": "/contents/images/news/thumb/news_thumb_0096.png",
        "tags": [
            "#비대면간담회",
            "#비대면생방송",
            "#언텍트방송",
            "#VirtualAudience"
        ],
        "writeTime": "2021.12.29 3:47"
    },
    {
        "title": "청주서부소방서, 소방드론 활용 화재예방순찰",
        "link": "http://m.fpn119.co.kr/170117",
        "thumbnail": "/contents/images/news/thumb/news_thumb_0095.png",
        "tags": [
            "#충북소방서",
            "#충북소방본부",
            "#T라이브캐스터",
            "#TLCSmartDrone"
        ],
        "writeTime": "2021.12.28 19:00"
    },
    {
        "title": "음성소방서, ‘소방드론’ 운용능력 향상 훈련",
        "link": "http://m.fpn119.co.kr/170135",
        "thumbnail": "/contents/images/news/thumb/news_thumb_0094.png",
        "tags": [
            "#음성소방서",
            "#충북소방본부",
            "#드론영상관제시스템",
            "#T라이브캐스터"
        ],
        "writeTime": "2021.12.28 18:02"
    },
    {
        "title": "보은소방서, 소방드론 운용자 역량강화 교육 실시",
        "link": "https://www.ccdailynews.com/news/articleView.html?idxno=2101229",
        "thumbnail": "/contents/images/news/thumb/news_thumb_0093.png",
        "tags": [
            "#보은소방",
            "#충북소방본부",
            "#드론영상관제",
            "#T라이브캐스터"
        ],
        "writeTime": "2021.12.17 16:32"
    },
    {
        "title": "진천소방서, 재난현장 '드론 라이브시스템' 구축",
        "link": "http://m.fpn119.co.kr/168781",
        "thumbnail": "/contents/images/news/thumb/news_thumb_0092.png",
        "tags": [
            "#진천소방서",
            "#충북소방본부",
            "#드론영상관제",
            "#T라이브캐스터"
        ],
        "writeTime": "2021.12.06 19:00"
    },
    {
        "title": "괴산소방서, 소방드론으로 신속한 긴급구조체계 구축",
        "link": "http://m.fpn119.co.kr/168782",
        "thumbnail": "/contents/images/news/thumb/news_thumb_0091.png",
        "tags": [
            "#괴산소방서",
            "#충북소방본부",
            "#드론영상관제",
            "#Tlivecaster"
        ],
        "writeTime": "2021.12.06 16:00"
    },
    {
        "title": "부산 드론활용서비스 실증사업 성과보고회 24일 개최",
        "link": "https://n.news.naver.com/mnews/article/001/0012803068?sid=102&lfrom=facebook",
        "thumbnail": "/contents/images/news/thumb/news_thumb_0090.png",
        "tags": [
            "#부산테크노파크",
            "#드론영상관제",
            "#T라이브캐스터",
            "#Tlivecaster"
        ],
        "writeTime": "2021.11.19 19:00"
    },
    {
        "title": "드론 등 안전장비에 IT 접목… 소방산업 미래 한눈에",
        "link": "https://n.news.naver.com/mnews/article/014/0004742181?sid=102&lfrom=facebook",
        "thumbnail": "/contents/images/news/thumb/news_thumb_0089.png",
        "tags": [
            "#국제소방안전박람회",
            "#스카이엔터프라이즈",
            "#T라이브캐스터",
            "#드론영상관제",
            "#Tlivecaster"
        ],
        "writeTime": "2021.11.18 18:00"
    },
    {
        "title": "실종자 수색 드론 영상, 전용 관제차에서 종합 분석한다",
        "link": "https://n.news.naver.com/article/001/0012790683?lfrom=facebook",
        "thumbnail": "/contents/images/news/thumb/news_thumb_0088.png",
        "tags": [
            "#경찰청",
            "#폴리스드론",
            "#유맥에어",
            "#드론영상관제",
            "#TLCIPConverter"
        ],
        "writeTime": "2021.11.14 06:00"
    },
    {
        "title": "2025년, ‘하늘을 나는 택시’로 도심-공항 20분 교통 생활권이 가능해진다",
        "link": "https://www.boannews.com/media/view.asp?idx=102379",
        "thumbnail": "/contents/images/news/thumb/news_thumb_0087.png",
        "tags": [
            "#K-UAM",
            "#드론택시",
            "#T라이브캐스터",
            "#TLCSmart"
        ],
        "writeTime": "2021.11.12 09:49"
    },
    {
        "title": "대구소방, 드론영상 관제시스템 구축",
        "link": "https://news.kbs.co.kr/news/view.do?ncd=5322470",
        "thumbnail": "/contents/images/news/thumb/news_thumb_0086.png",
        "tags": [
            "#대구소방안전본부",
            "#드론영상관제",
            "#드론관제차량",
            "#T러이브캐스터"
        ],
        "writeTime": "2021.11.11 08:19"
    },
    {
        "title": "대구소방, 재난현장 실시간 드론 영상 관제시스템 구축",
        "link": "https://www.idaegu.co.kr/news/articleView.html?idxno=363401",
        "thumbnail": "/contents/images/news/thumb/news_thumb_0085.png",
        "tags": [
            "#대구소방안전본부",
            "#드론영상관제",
            "#드론관제차량",
            "#T러이브캐스터"
        ],
        "writeTime": "2021.11.10 18:40"
    },
    {
        "title": "최근 ‘탈통신’으로 눈을 돌리는 이동통신사들도 클라우드에 기반한 구독형 SW 서비스 시장에 참전하고 있다.",
        "link": "https://m.sedaily.com/NewsView/22SWJXZ4Q3#cb",
        "thumbnail": "/contents/images/news/thumb/news_thumb_0084.png",
        "tags": [
            "#SK텔레콤",
            "#SKTelecom",
            "#영상관제시스템",
            "#T라이브캐스터"
        ],
        "writeTime": "2021.11.06 08:23"
    },
    {
        "title": "대구경찰청, 항공영상 및 순찰차캠 활용 대테러 훈련 실시",
        "link": "https://www.idaegu.com/newsView/idg202110280036",
        "thumbnail": "/contents/images/news/thumb/news_thumb_0083.png",
        "tags": [
            "#대구경찰청",
            "#순찰차캠",
            "#영상관제시스템",
            "#T라이브캐스터"
        ],
        "writeTime": "2021.11.01 14:17"
    },
    {
        "title": "유영상 SK텔레콤 사장, SK텔레콤 2.0 시대 개막 선언",
        "link": "https://www.etnews.com/20211101000256?SNS=00002",
        "thumbnail": "/contents/images/news/thumb/news_thumb_0082.png",
        "tags": [
            "#SK텔레콤타운홀",
            "#BroadSiteLive",
            "#비대면행사",
            "#VirtualAudience",
            "#시청자참여생방송"
        ],
        "writeTime": "2021.11.01 12:15"
    },
    {
        "title": "[극한데뷔 야생돌 선공개] 야생돌 첫 대중 평가, '타이틀곡 미션'🔥 100인의 선택을 받은 단 하나의 팀은?, MBC 211028 방송",
        "link": "https://www.youtube.com/watch?t=138&v=25A1ZKQT1tg&feature=youtu.be",
        "thumbnail": "/contents/images/news/thumb/news_thumb_0080.png",
        "tags": [
            "#MBC",
            "#극한데뷔야생돌",
            "#비대면생방송",
            "#언택트방송",
            "#VirtualAudience",
            "#BroadSiteLive"
        ],
        "writeTime": "2021.10.29 08:39"
    },
    {
        "title": "서부발전, 재난대응훈련에 디지털신기술 도입·적용",
        "link": "http://www.energydaily.co.kr/news/articleView.html?idxno=122719",
        "thumbnail": "/contents/images/news/thumb/news_thumb_0079.png",
        "tags": [
            "#한국서부발전",
            "#현장영상관제시스템",
            "#T라이브캐스터",
            "#드론영상관제",
            "#TLC"
        ],
        "writeTime": "2021.10.28 15:39"
    },
    {
        "title": "만안청소년수련관, 취미활동이 직업이 될 수 있다! 주제로",
        "link": "https://www.youtube.com/watch?v=00qEJakyWjM",
        "thumbnail": "/contents/images/news/thumb/news_thumb_0078.png",
        "tags": [
            "#SK브로드밴드",
            "#수원방송총국",
            "#T라이브캐스터",
            "#TLCSmart",
            "#OCTO"
        ],
        "writeTime": "2021.10.20 16:30"
    },
    {
        "title": "[포토] 차량형 드론 관제시스템 체험",
        "link": "http://www.incheonilbo.com/news/articleView.html?idxno=1116880",
        "thumbnail": "/contents/images/news/thumb/news_thumb_0077.png",
        "tags": [
            "#폴리스드론",
            "#경찰청",
            "#드론영상관제",
            "#T라이브캐스터",
            "#TLCIPConverter",
            "#유맥에어"
        ],
        "writeTime": " 2021.10.20 20:00"
    },
    {
        "title": "충북소방, 제1회 전국 소방드론 경진대회 ‘2위’ 쾌거",
        "link": "https://www.fpn119.co.kr/165933",
        "thumbnail": "/contents/images/news/thumb/news_thumb_0076.png",
        "tags": [
            "#충북소방본부",
            "#소방청장배드론경진대회",
            "#드론영상관제",
            "#T라이브캐스터",
            "#TLCSmartDrone"
        ],
        "writeTime": "2021.10.21 17:05"
    },
    {
        "title": "예산군, 충남도 ‘드론활용 분야’ 최우수상 수상",
        "link": "https://n.news.naver.com/article/421/0005659787?lfrom=facebook",
        "thumbnail": "/contents/images/news/thumb/news_thumb_0075.png",
        "tags": [
            "#예산군",
            "#충청남도",
            "#드론영상관제",
            "#TLCSmartDrone",
            "#T라이브캐스터"
        ],
        "writeTime": "2021.10.15 12:51"
    },
    {
        "title": "창고 방치된 조달청 드론…사놓고 수년간 미사용 '방치'",
        "link": "https://n.news.naver.com/article/421/0005649774?lfrom=facebook",
        "thumbnail": "/contents/images/news/thumb/news_thumb_0074.png",
        "tags": [
            "#드론뉴스"
        ],
        "writeTime": "2021.10.11 11:56"
    },
    {
        "title": "'드론산업 중심지' 꿈꾸는 고양시, 앵커센터 설치로 '인프라 확장'",
        "link": "http://m.kyeongin.com/view.php?key=20210925010003947",
        "thumbnail": "/contents/images/news/thumb/news_thumb_0073.png",
        "tags": [
            "#고양시",
            "#드론앵커센터",
            "#SK텔레콤",
            "#T라이브캐스터"
        ],
        "writeTime": "2021.09.25 18:27"
    },
    {
        "title": "재난 현장을 드론으로 실시간 생중계 할 수 있다!?",
        "link": "https://www.youtube.com/watch?v=l4ayLBahvUM",
        "thumbnail": "/contents/images/news/thumb/news_thumb_0072.png",
        "tags": [
            "#충남도청",
            "#드론영상관제시스템",
            "#T라이브캐스터",
            "#Tlivestudio",
            "#TLC"
        ],
        "writeTime": "2021.09.15 20:21"
    },
    {
        "title": "충북소방본부, 추석 명절 드론 순찰 강화",
        "link": "https://n.news.naver.com/article/079/0003553958?lfrom=facebook",
        "thumbnail": "/contents/images/news/thumb/news_thumb_0070.png",
        "tags": [
            "#충북소방본부",
            "#드론영상관제시스템",
            "#T라이브캐스터",
            "#Tlivestudio"
        ],
        "writeTime": "2021.09.15 20:21"
    },
    {
        "title": "한수원, 유선망 장애 SKT '프라이빗-5G 비상통신'으로 대응",
        "link": "http://m.inews24.com/v/1403820#_DYAD",
        "thumbnail": "/contents/images/news/thumb/news_thumb_0071.png",
        "tags": [
            "#한국수력원자력",
            "#T라이브캐스터",
            "#TLCLandview"
        ],
        "writeTime": "2021.09.13 08:45"
    },
    {
        "title": "예산군, 1789부대와 드론 활용 관·군 수색정찰 합동훈련 실시",
        "link": "https://www.ccdailynews.com/news/articleView.html?idxno=2080076",
        "thumbnail": "/contents/images/news/thumb/news_thumb_0068.png",
        "tags": [
            "#예산군",
            "#충남도청",
            "#드론영상관제",
            "#T라이브캐스터",
            "#TLCSmartDrone",
            "#Tlivestudio"
        ],
        "writeTime": "2021.09.06 13:08"
    },
    {
        "title": "‘스마트 건설 챌린지 2021’ 개막… 국토안전관리원 등 공동주관",
        "link": "https://www.cnbnews.com/news/article.html?no=508604",
        "thumbnail": "/contents/images/news/thumb/news_thumb_0067.png",
        "tags": [
            "#스마트건설 챌린지",
            "#TLCLandview",
            "#T라이브캐스터"
        ],
        "writeTime": "2021.09.01 11:20"
    },
    {
        "title": "김천시 남부권 스마트물류 거점도시 '시동'",
        "link": "https://www.newsis.com/view/?id=NISX20210826_0001560598",
        "thumbnail": "/contents/images/news/thumb/news_thumb_0066.png",
        "tags": [
            "#김천시",
            "#SK텔레콤",
            "#디지털물류",
            "#드론배송",
            "#드론영상관제",
            "#T라이브캐스터"
        ],
        "writeTime": "2021.08.26 06:26"
    },
    {
        "title": "드론·로봇 배송 앞당긴다…김천서 디지털 물류 실증",
        "link": "https://www.yna.co.kr/view/AKR20210823054200053?input=1195m",
        "thumbnail": "/contents/images/news/thumb/news_thumb_0065.png",
        "tags": [
            "#김천시",
            "#드론배송",
            "#드론영상관제",
            "#T라이브캐스터",
            "#Tlivecaster",
            "#TLCSmart",
            "#Tlivestudio"
        ],
        "writeTime": "2021.08.22 23:09"
    },
    {
        "title": "'드론 띄워 수색부터 구조까지' 진화하는 재난 초기 대응 체계",
        "link": "https://n.news.naver.com/article/421/0005554563?lfrom=facebook",
        "thumbnail": "/contents/images/news/thumb/news_thumb_0064.png",
        "tags": [
            "#충북소방본부",
            "#드론영상관제",
            "#TLCSmartDrone",
            "#Tlivestudio",
            "#T라이브캐스터"
        ],
        "writeTime": "2021.08.22 12:49"
    },
    {
        "title": "SKT, 고양시와 드론산업 키운다…생태계 구축 MOU 체결",
        "link": "https://n.news.naver.com/article/629/0000101429?lfrom=facebook",
        "thumbnail": "/contents/images/news/thumb/news_thumb_0062.png",
        "tags": [
            "#고양시",
            "#SK텔레콤",
            "#MOU",
            "#T라이브캐스터",
            "#TLC",
            "#TlivecasterTX"
        ],
        "writeTime": "2021.08.19 16:30"
    },
    {
        "title": "산에서 길 잃은 조난객 찾아낸 ‘드론’…하루에 2명 구조",
        "link": "https://news.kbs.co.kr/mobile/news/view.do?ncd=5258840",
        "thumbnail": "/contents/images/news/thumb/news_thumb_0061.png",
        "tags": [
            "#충주소방서",
            "#충북소방본부",
            "#T라이브캐스터",
            "#TLC",
            "#TLCSmartDrone",
            "#Tlivestudio"
        ],
        "writeTime": "2021.08.18 17:31"
    },
    {
        "title": "소방 드론, 산악 조난사고 현장 구조활동 톡톡",
        "link": "https://n.news.naver.com/article/005/0001465013?lfrom=facebook",
        "thumbnail": "/contents/images/news/thumb/news_thumb_0060.png",
        "tags": [
            "#충주소방서",
            "#충북소방본부",
            "#T라이브캐스터",
            "#TLCSmartDrone",
            "#Tlivestudio"
        ],
        "writeTime": "2021.08.17 20:02"
    },
    {
        "title": "드론·인공지능 활용해 해양쓰레기 관리한다",
        "link": "https://www.ksg.co.kr/news/main_newsView.jsp?pNum=131299&fbclid=IwAR02xk38bCB87gt2zewM-JPDtFkQh_2fYmv9TZjN89cHbq4diHD1y51-yCs",
        "thumbnail": "/contents/images/news/thumb/news_thumb_0059.png",
        "tags": [
            "#아이렘기술개발",
            "#수중드론",
            "#TlivecasterPlan",
            "#TLCSmartPlan"
        ],
        "writeTime": "2021.08.10 10:13"
    },
    {
        "title": "신라대학교, 항공·드론 거점 캠퍼스로 비상…최초합격자 전원 장학금 지급...",
        "link": "http://www.kookje.co.kr/news2011/asp/newsbody.asp?code=0300&key=20210811.22036006712&fbclid=IwAR3Ub2U5jpzqAA-mAD2O2PSmQeDoh0MNyc5CLuLebgnXIDAMeZcEOTRUhqg",
        "thumbnail": "/contents/images/news/thumb/news_thumb_0058.png",
        "tags": [
            "#신라대학교",
            "#TLC",
            "#T라이브캐스터",
            "#Tlivestudio",
            "#TLCSMARTDRONE"
        ],
        "writeTime": "2021.08.10 08:20"
    },
    {
        "title": "대부분의 T라이브캐스터 도입 고객사들은, 드론을 업무에 활발하게 적용하고 있습니다.",
        "link": "https://www.taxtimes.co.kr/news/article.html?no=251159&fbclid=IwAR2bUhqAA3J2FJIS-12j0X-3uipY4upA4fF9WKm4FQAiVNnMU_Bwmom96hk",
        "thumbnail": "/contents/images/news/thumb/news_thumb_0057.png",
        "tags": [
            "#관세청",
            "#드론영상관제",
            "#TLCSmartDrone",
            "#Tlivestudio",
            "#T라이브캐스터"
        ],
        "writeTime": "2021.08.11 13:58"
    },
    {
        "title": "나이트 레이스 6Hrs 마지막 이야기 - 슈퍼 6000 클래스 결승의 시간",
        "link": "https://www.youtube.com/watch?v=Yk9bG7dPi3Q",
        "thumbnail": "/contents/images/news/thumb/news_thumb_0056.png",
        "tags": [
            "#CJ슈퍼레이스",
            "#LiveWide",
            "#카레이싱중계",
            "#온보드카메라",
            "#Onboardcamera",
            "#T라이브캐스터"
        ],
        "writeTime": "2021.08.08 10:52"
    },
    {
        "title": "예산군, 드론 활용한 행정혁신 인력 양성",
        "link": "http://www.ggilbo.com/news/articleView.html?idxno=862459&fbclid=IwAR20GkdyBreLer3YWvWTqii6zY3gNVzQZSy3-onr48__SVUqe525V62Pyf8",
        "thumbnail": "/contents/images/news/thumb/news_thumb_0055.png",
        "tags": [
            "#예산군청",
            "#충남도청",
            "#드론영상관제",
            "#TLCSmartDrone",
            "#Tlivestudio",
            "#T라이브캐스터"
        ],
        "writeTime": "2021.08.09 10:41"
    },
    {
        "title": "'구조 활동 속도 낸다' 충주소방서, 수색 드론 11대 도입",
        "link": "https://n.news.naver.com/article/421/0005527352?lfrom=facebook&fbclid=IwAR1g6TicuAVbFYHsGXRJ1OhP5BS1E-nztR3UcjMhcxPH0xiPJZg68ZnlOKk",
        "thumbnail": "/contents/images/news/thumb/news_thumb_0054.png",
        "tags": [
            "#충북소방서",
            "#충북소방본부",
            "#드론영상관제",
            "#TLCSmartDrone",
            "#Tlivestudio",
            "#T라이브캐스터"
        ],
        "writeTime": "2021.08.06 16:20"
    },
    {
        "title": "옥천소방서, 재난현장 신속 파악“소방드론”신규 배치",
        "link": "http://www.bzeronews.com/news/articleView.html?idxno=484903&fbclid=IwAR2bUhqAA3J2FJIS-12j0X-3uipY4upA4fF9WKm4FQAiVNnMU_Bwmom96hk",
        "thumbnail": "/contents/images/news/thumb/news_thumb_0053.png",
        "tags": [
            "#옥천소방서",
            "#충북소방본부",
            "#드론영상관제",
            "#TLCSmartDrone",
            "#Tlivestudio",
            "#T라이브캐스터"
        ],
        "writeTime": "2021.08.06 10:13"
    },
    {
        "title": "드론 활용한 폭염 예찰 활동 실시",
        "link": "https://www.ccdailynews.com/news/articleView.html?idxno=2073439&fbclid=IwAR3DRvY2Kgqu0Vhy3OISAt2FC14hwrvSmL6b2UtcU9mFe2thW1s6l_BLn7w",
        "thumbnail": "/contents/images/news/thumb/news_thumb_0052.png",
        "tags": [
            "#예산군",
            "#충남도청",
            "#드론영상관제",
            "#TLCSmartDrone",
            "#Tlivestudio",
            "#T라이브캐스터"
        ],
        "writeTime": "2021.08.02 10:52"
    },
    {
        "title": "보은소방서, 작전지휘 소방드론 운용",
        "link": "https://www.fpn119.co.kr/161475?fbclid=IwAR10dipUBVg2a0-0QGePq_F7yLSLfCEcNeqoC_ua6wxkLl01tqR3uMtra3g",
        "thumbnail": "/contents/images/news/thumb/news_thumb_0051.png",
        "tags": [
            "#보은소방서",
            "#충북소방본부",
            "#TLCSmartDrone",
            "#T라이브캐스터",
            "#Tlivestudio",
            "#드론영상관제"
        ],
        "writeTime": "2021.07.31 11:17"
    },
    {
        "title": "보령시, 드론을 활용한 물놀이 안전 계도 활동",
        "link": "http://www.chungnamilbo.co.kr/news/articleView.html?idxno=615084&fbclid=IwAR26Tb0lB7CPGeSHF541fHwPz2RX6U9VlQ0N17Ni_i8V_ogNLW--rRpWn3o",
        "thumbnail": "/contents/images/news/thumb/news_thumb_0050.png",
        "tags": [
            "#보령시",
            "#충남도청",
            "#드론영상관제",
            "#TLCSmartDrone",
            "#Tlivestudio",
            "#T라이브캐스터"
        ],
        "writeTime": "2021.07.27 21:59"
    },
    {
        "title": "도-시군 드론통합관제시스템 운영 연동훈련 실시",
        "link": "http://www.ccdailynews.com/news/articleView.html?idxno=2071857&fbclid=IwAR2fcZbu5hgAgBQAg06Y-sKR54-XAGsrkXHx1gI4Al2WpkWbyFcWvof2yEc",
        "thumbnail": "/contents/images/news/thumb/news_thumb_0049.png",
        "tags": [
            "#충청남도",
            "#예산군",
            "#드론통합관제시스템",
            "#TLC",
            "#T라이브캐스터",
            "#TLCSmartDrone"
        ],
        "writeTime": "2021.07.25 03:43"
    },
    {
        "title": "청주동부소방서, 소방드론으로 현장 정보 더 빠르게 습득한다",
        "link": "http://m.fpn119.co.kr/161128?fbclid=IwAR3dAEQMb86Qw6i1vjm2rRrc-FxYFYff6iwsIF_sp3SQqYRlhE4U3oWxhj0",
        "thumbnail": "/contents/images/news/thumb/news_thumb_0048.png",
        "tags": [
            "#청주동부소방서",
            "#충북소방본부",
            "#TLCSmartDrone",
            "#TLC",
            "#T라이브캐스터",
            "#Tlivecaster"
        ],
        "writeTime": "2021.07.23 11:09"
    },
    {
        "title": "SK브로드밴드,전국 생방송 지원 솔루션 개발...지역채널 적용",
        "link": "https://m.etnews.com/20210707000079?fbclid=IwAR3XA9euyj8eTCKpse2lZLQXrXFGQHAQ430h0hYeYaaaUXqBqdInfh5jDlc",
        "thumbnail": "/contents/images/news/thumb/news_thumb_0047.png",
        "tags": [
            "#SK브로드밴드",
            "#SK텔레콤",
            "#스마트폰생방송",
            "#드론생방송",
            "#TLCSmart",
            "#TLCSmartDrone"
        ],
        "writeTime": "2021.07.07 10:56"
    },
    {
        "title": "‘나이트 레이스’, 현역 최강자는 누구? 한여름 밤의 짜릿한 나이트레이스 채널A에서 즐겨보세요",
        "link": "https://sports.donga.com/article/all/20210706/107812049/1?fbclid=IwAR2wYxjNAbAbukvrNoISNxS198Lh2OXtC4JOn5hebQgpSOKjI1Z4p1a_FUc",
        "thumbnail": "/contents/images/news/thumb/news_thumb_0046.png",
        "tags": [
            "#CJ슈퍼레이스",
            "#온보드카메라",
            "#T라이브캐스터",
            "#TLCSmart",
            "#스마트폰생중계"
        ],
        "writeTime": "2021.07.06 11:49"
    },
    {
        "title": "‘스마트드론’ 띄워 폐기물 매립 감시… 수도권 위생생활 책임진다 [공공 안전경영 시대]",
        "link": "https://www.fnnews.com/news/202107061820505653?fbclid=IwAR1DL0Mjs_i3ddGR86ADkl1t1h0Sc-RZsJJjFa5KrT6EDHxrWFWh0RNleaM",
        "thumbnail": "/contents/images/news/thumb/news_thumb_0045.png",
        "tags": [
            "#TLCSmartDrone",
            "#T라이브캐스터",
            "#드론영상관제"
        ],
        "writeTime": "2021.07.06 18:20"
    },
    {
        "title": "안산시-SKT-유맥에어, 산단 드론 관제 시스템 구축… 11월까지 추진",
        "link": "http://www.joongboo.com/news/articleView.html?idxno=363491359&fbclid=IwAR3XA9euyj8eTCKpse2lZLQXrXFGQHAQ430h0hYeYaaaUXqBqdInfh5jDlc",
        "thumbnail": "/contents/images/news/thumb/news_thumb_0043.png",
        "tags": [
            "#안산시",
            "#반월국가산업단지드론연계관제시스템",
            "#유맥에어",
            "#T라이브캐스터",
            "#Tlivestudio"
        ],
        "writeTime": "2021.06.22 11:22"
    },
    {
        "title": "드론 연계 산업플랜트용 관제시스템(안산시, ㈜유맥에어, SK탤레콤)",
        "link": "https://www.dnews.co.kr/m_home/view.jsp?idxno=202106171009591780304&fbclid=IwAR2k_55QpycPownUMm-PrFFhW3xMlo5koJWPQhqiHEa0c7e2IW9lIwdNSrk",
        "thumbnail": "/contents/images/news/thumb/news_thumb_0042.png",
        "tags": [
            "#SK텔레콤",
            "#발명의날",
            "#TLCLandView"
        ],
        "writeTime": "2021.06.17 13:39"
    },
    {
        "title": "위급상황 더 빛나는 소방 드론",
        "link": "https://www.cjb.co.kr/home/sub.php?menukey=61&mod=view&P_NO=210614053&PRO_CODE=4&fbclid=IwAR0EVyfSAsN5hWNzES3DiuToxA6W1TYusVpWY6QjjESj2w4C5D3qHPdwzq0",
        "thumbnail": "/contents/images/news/thumb/news_thumb_0044.png",
        "tags": [
            "#소방드론전술훈련",
            "#충북소방본부",
            "#TLC",
            "#TLCSmartDrone",
            "#T라이브캐스터"
        ],
        "writeTime": "2021.06.15 13:39"
    },
    {
        "title": "수색부터 구조까지...드론, 재난 현장서 활약",
        "link": "https://n.news.naver.com/article/056/0011064290?lfrom=facebook&fbclid=IwAR0-f9ySXR41GGgyb7it2bje8EYy2PPC2OgSijJezZlNQgNJ10FYRJ9e5qo",
        "thumbnail": "/contents/images/news/thumb/news_thumb_0041.png",
        "tags": [
            "#소방드론전술훈련",
            "#충북소방본부",
            "#TLCSmartDrone",
            "#T라이브캐스터"
        ],
        "writeTime": "2021.06.15 10:02"
    },
    {
        "title": "재난 대응 '드론'활용",
        "link": "https://www.facebook.com/Tlivecaster.page/photos/p.2998339947160073/2998339947160073/?type=3",
        "thumbnail": "/contents/images/news/thumb/news_thumb_0040.png",
        "tags": [
            "#SK텔레콤",
            "#재난대응",
            "#드론활용"
        ],
        "writeTime": "2021.06.03 12:30"
    },
    {
        "title": "SK텔레콤, 제56회 발명의 날 은탑 산업훈장 수상",
        "link": "https://m.etnews.com/20210531000220?fbclid=IwAR0TNf5aQinI5fJKv0cQ5kJ5pykQSQp0tOKaDxH8yXT9HPvRwbI7sr9pS0M",
        "thumbnail": "/contents/images/news/thumb/news_thumb_0039.png",
        "tags": [
            "#SK텔레콤",
            "#발명의날",
            "#TLCLandView"
        ],
        "writeTime": "2021.05.31 15:33"
    },
    {
        "title": "미디어·배송 등 '구독서비스' 확대하는 SKT",
        "link": "https://n.news.naver.com/article/015/0004550953?lfrom=facebook&fbclid=IwAR3D8nI7vcu9qui-gzA98Gt_DwaEE0mtkkVL2GmglJddlTpJ444ROStS170",
        "thumbnail": "/contents/images/news/thumb/news_thumb_0038.png",
        "tags": [
            "#TLCSmartDroneplan",
            "#TLCSmartplan",
            "#T라이브캐스터",
            "#TLC",
            "#Tlivecasterplan"
        ],
        "writeTime": "2021.05.24 15:36"
    },
    {
        "title": "김천시, ‘배송 드론·로봇’ 활용 물류 첨단화 앞당긴다",
        "link": "http://www.hidomin.com/news/articleView.html?idxno=453569&fbclid=IwAR2EhoF_5BP6TupRW9MkeM0vQ5CZqBZ27F0rUyxmYFVNvxm8t3bnywDXAck",
        "thumbnail": "/contents/images/news/thumb/news_thumb_0037.png",
        "tags": [
            "#김천시",
            "#디지털물류서비스",
            "#드론",
            "#TLC",
            "#Tlivecaster"
        ],
        "writeTime": "2021.05.20 18:41"
    },
    {
        "title": "SK텔레콤 'T라이브 캐스터' 구독 서비스로 출시",
        "link": "https://n.news.naver.com/article/030/0002945616?lfrom=facebook&fbclid=IwAR15u2Q4NYaxUb7DF8AGTEdy0l8_55l_nmKPTjJY-kiFgp457lYXhHT7LjQ",
        "thumbnail": "/contents/images/news/thumb/news_thumb_0036.png",
        "tags": [
            "#TLC",
            "#T라이브캐스터",
            "#구독서비스출시",
            "#TLCSmart",
            "#TLCSmartDrone"
        ],
        "writeTime": "2021.05.20 10:13"
    },
    {
        "title": "산림항공본부, 산림재해에 효율적이고 안전한 드론 이용!",
        "link": "http://www.gukjenews.com/news/articleView.html?idxno=2217067&fbclid=IwAR0SGuwybGa3DVnmUWm33u74dsrn6fLMDD5hv4QBhHkuG2tRxzWtRfLkrKk",
        "thumbnail": "/contents/images/news/thumb/news_thumb_0035.png",
        "tags": [
            "#산림항공본부",
            "#SK텔레콤",
            "#T라이브캐스터"
        ],
        "writeTime": "2021.05.04 20:41"
    },
    {
        "title": "[부산]'드론, 산업을 연결하다' 드론쇼 코리아 개막",
        "link": "https://www.youtube.com/watch?v=lHEjCUsCtKo&t=9000s",
        "thumbnail": "/contents/images/news/thumb/news_thumb_0033.png",
        "tags": [
            "#AVING",
            "#avingnews",
            "#드론쇼코리아",
            "#TLC",
            "#T라이브캐스터"
        ],
        "writeTime": "2021.05.04 09:16"
    },
    {
        "title": "[100분토론LIVE] - (914회) 우리 아이들을 지켜주세요",
        "link": "https://www.youtube.com/watch?v=RYA9o90wC0c&t=3242s",
        "thumbnail": "/contents/images/news/thumb/news_thumb_0034.png",
        "tags": [
            "#100분 토론",
            "#Broadsite Live",
            "#MBC",
            "#생중계 방송",
            "#시청자 참여"
        ],
        "writeTime": "2021.05.03 10:40"
    },
    {
        "title": "[부산]'드론, 산업을 연결하다' 드론쇼 코리아 개막",
        "link": "https://www.youtube.com/watch?v=mS1O209qfEQ",
        "thumbnail": "/contents/images/news/thumb/news_thumb_0032.png",
        "tags": [
            "#SK브로드밴드",
            "#부산방송",
            "#드론쇼코리아",
            "#T라이브캐스터",
            "#TLCSmartDrone"
        ],
        "writeTime": "2021.04.30 16:30"
    },
    {
        "title": "항상 소방현장과 함께 합니다.",
        "link": "https://m.ytn.co.kr/news_view.php?s_mcd=0103&key=202104290501250094&pos&fbclid=IwAR2Dv5MyPehpZG23xShkyDhVsNPLHtjvOlO4_j2Res-PrbiX0K_-4G2_7Fw#return",
        "thumbnail": "/contents/images/news/thumb/news_thumb_0031.png",
        "tags": [
            "#T라이브캐스터",
            "#TLC",
            "#TLCSmart",
            "#Tlivecaster",
            "#부산소방재난본부"
        ],
        "writeTime": "2021.04.29 17:01"
    },
    {
        "title": "'2021 드론쇼코리아' 개막…부산 벡스코서 130개사 참가",
        "link": "https://n.news.naver.com/article/030/0002941525?lfrom=facebook&fbclid=IwAR3N1ZC-XicGkQCkUaWDst8kewW5cwCFQJIchZXdxirMUtCKs2BidUzfGPU",
        "thumbnail": "/contents/images/news/thumb/news_thumb_0030.png",
        "tags": [
            "#드론쇼코리아",
            "#SK텔레콤",
            "#T라이브캐스터",
            "#TLC",
            "#드론영상관제"
        ],
        "writeTime": "2021.04.27 12:54"
    },
    {
        "title": "청양군이 드론과 인연을 맺은 건 충남도가 전국...",
        "link": "https://n.news.naver.com/article/047/0002307801?lfrom=facebook&fbclid=IwAR0uoW-rdB3avwiDM_dWcUM3kkEfxIXmejAxAvXoSgJV7rW7QaBD6y_-hyA",
        "thumbnail": "/contents/images/news/thumb/news_thumb_0027.png",
        "tags": [
            "#청양군",
            "#충청남도",
            "#드론영상관제",
            "#TLC",
            "#TLSmartDrone"
        ],
        "writeTime": "2021.04.22 08:22"
    },
    {
        "title": "화마 뚫고 종횡무진…재난 현장 누비는 소방 드론",
        "link": "https://imnews.imbc.com/replay/2021/nwdesk/article/6147457_34936.html?fbclid=IwAR1LdjF9Dcr6V6WUQzwTPNlIZ5yiRB5jWY6su-U7XCULlP1ZEN1WdCxn4Jc",
        "thumbnail": "/contents/images/news/thumb/news_thumb_0028.png",
        "tags": [
            "#소방관",
            "#소방본",
            "#드론영상관제",
            "#TLC",
            "#TLSmartDrone"
        ],
        "writeTime": "2021.04.12 20:42"
    },
    {
        "title": "관악구 \"자율순찰 로봇으로 여성 안전 귀가 노력\"",
        "link": "https://www.ajunews.com/view/20210408085616057?fbclid=IwAR2ZDJ5FpRzcPeUt0aEtxSUwWTYfSyFpxm3vEp46xlhw6QLgQ-2AqFPWRZw",
        "thumbnail": "/contents/images/news/thumb/news_thumb_0029.png",
        "tags": [
            "#관악구",
            "#자율주행로봇",
            "#자율순찰로봇",
            "#만도",
            "#로봇영상관제"
        ],
        "writeTime": "2021.04.08 09:25"
    },
    {
        "title": "통영시, 드론 전문인력 양성으로 공공 혁신 이끈다…",
        "link": "https://www.boannews.com/media/view.asp?idx=95465&fbclid=IwAR2MOFjOjvRbVmHfFXWcfXvmKT3tVqxMIxgKPNaZRGduVySvudvT6PlE51o",
        "thumbnail": "/contents/images/news/thumb/news_thumb_0026.png",
        "tags": [
            "#통영시",
            "#드론영상관제",
            "#TLC",
            "#TLCSmartDrone"
        ],
        "writeTime": "2021.03.10 10:34"
    },
    {
        "title": "대한상의, 비대면으로 타운홀 미팅 개최",
        "link": "https://news.v.daum.net/v/20210329160014449?fbclid=IwAR2JzgSzAtfIJh8paFpP58U2_QCwhz2iCQw0BxqyvA_CsN1I6tTt8NNBB9A#none",
        "thumbnail": "/contents/images/news/thumb/news_thumb_0025.png",
        "tags": [
            "#대한상공회의소",
            "#비대면",
            "#시청자참여생방송",
            "#언택트행사",
            "#VirtualAudience",
            "#BroadSiteLive",
            "#TLC",
            "#타운홀"
        ],
        "writeTime": "2021.03.29 16:00"
    },
    {
        "title": "미세먼지 잡아라\"‥드론 띄우고 특수 차량 투입",
        "link": "http://andongmbc.co.kr/adboard/NewsView50640?fbclid=IwAR3z8YJIp9pQfsd9-344raT3UjMXC5klZBzYalidpdDwZshT2vHhiZnDDzs",
        "thumbnail": "/contents/images/news/thumb/news_thumb_0024.png",
        "tags": [
            "#달서구청",
            "#미세먼지",
            "#드론영상관제",
            "#TLC",
            "#TLCSmartDrone",
            "#T라이브캐스터"
        ],
        "writeTime": "2021.03.18 07:32"
    },
    {
        "title": "최태원 회장, 전국상의 회장단 온라인 상견례...",
        "link": "http://www.smedaily.co.kr/news/articleView.html?idxno=199883&fbclid=IwAR2D5aLX17NbCKcCj0AMdII20jjxHY3Ip34xR_Q0FW0naiYlpAdz2lTPHOU",
        "thumbnail": "/contents/images/news/thumb/news_thumb_0023.png",
        "tags": [
            "#대한상공회의소",
            "#시청자참여생방송",
            "#언택트행사",
            "#VirtualAudience",
            "#BroadSiteLive",
            "#TLC"
        ],
        "writeTime": "2021.03.18 16:39"
    },
    {
        "title": "한빛소프트 계열사 ‘한빛드론’ 선제투자로 미래시장 '리더' 우뚝...",
        "link": "http://m.gametoc.hankyung.com/news/articleView.html?idxno=57763&fbclid=IwAR15cWkeQVEgXhywAJMBLiJFGfwsZ6jwhjyU7DxyGSM8hSBbyrZ1f5UfOAs",
        "thumbnail": "/contents/images/news/thumb/news_thumb_0022.png",
        "tags": [
            "#한빛드론",
            "#T라이브캐스터",
            "#TLCSmartDrone",
            "#TLC",
            "#드론영상관제"
        ],
        "writeTime": "2021.03.11 11:27"
    },
    {
        "title": "대한상의 직원 만난 최태원, 온라인서 첫 행보…",
        "link": "https://n.news.naver.com/article/031/0000586705?lfrom=facebook&fbclid=IwAR2KySdXFZLVPOJi0BPJv8nsjdF3pUEM0QlOMyZvnPo1d5-i-bPWPVAo01Y",
        "thumbnail": "/contents/images/news/thumb/news_thumb_0021.png",
        "tags": [
            "#BroadSiteLive",
            "#TLC",
            "#시청자참여생방",
            "#언텍트행사",
            "#언텍트방송",
            "#대한상공회의소"
        ],
        "writeTime": "2021.03.04 17:27"
    },
    {
        "title": "산림청이 전국에서 동시다발적으로 발생한 산불에 대해...",
        "link": "https://www.facebook.com/watch/?v=1355411961518287",
        "thumbnail": "/contents/images/news/thumb/news_thumb_0020.png",
        "tags": [
            "#충북소방본부",
            "#드론영상관제",
            "#드론관제차량",
            "#TLCSmartDroen",
            "#TLCSmart",
            "#T라이브캐스터"
        ],
        "writeTime": "2021.02.26 16:52"
    },
    {
        "title": "태안군, 스마트시티 통합플랫폼 구축...",
        "link": "http://m.daejonilbo.com/mnews.asp?pk_no=1459863&fbclid=IwAR0VnzeQveGdPmv9QMyiKPMTA3PnoHKm8wRj-0KyoU70LwRN8rqBhZAbRVs",
        "thumbnail": "/contents/images/news/thumb/news_thumb_0019.png",
        "tags": [
            "#태안군청",
            "#스마트 시티",
            "#TLCSmart",
            "#TLC Smart Drone",
            "#통합관제플랫폼"
        ],
        "writeTime": "2021.01.24 18:17"
    },
    {
        "title": "김창룡 경찰청장, 아스트라제네카 백신 에스코트 지휘...",
        "link": "https://n.news.naver.com/article/421/0005184979?lfrom=facebook&fbclid=IwAR36HLiM5ffSOyjLI27tSGmlLlCInYSuwL7GAn3sNByTj01BCujZlzhJp-A",
        "thumbnail": "/contents/images/news/thumb/news_thumb_0018.png",
        "tags": [
            "#순찰차캠영상관제시스템",
            "#경찰청",
            "#백신수송",
            "#TLC",
            "#TLC Smart",
            "#T라이브캐스"
        ],
        "writeTime": "2021.02.24 14:25"
    },
    {
        "title": "엘리오스2 LTE/5G 실시간 관제 시스템(SK텔레콤 T live caster)",
        "link": "https://www.youtube.com/watch?v=ZleQuFKayn4",
        "thumbnail": "/contents/images/news/thumb/news_thumb_0017.png",
        "tags": [
            "#AirSense",
            "#에어센스",
            "#엘리오스2",
            "#TLC Smart Drone",
            "#Tlivecaster"
        ],
        "writeTime": "2021.02.19 15:37"
    },
    {
        "title": "서천군 드론영상 중계시스템 행정서비스 효율성 제고",
        "link": "http://www.daejonilbo.com/news/newsitem.asp?pk_no=1458405&fbclid=IwAR0m5PiVGLV-3HRmdwM5q6McphHD5ke5tyUyhy9UpDcaibCXTsyN3R8YjDA",
        "thumbnail": "/contents/images/news/thumb/news_thumb_0016.png",
        "tags": [
            "#TLC",
            "#TLC Smart Drone",
            "#드론영상관제",
            "#서천군"
        ],
        "writeTime": "2021.02.14 12:04"
    },
    {
        "title": "재난현장 종횡무진 '충북 소방드론' (jbnews.com)",
        "link": "http://www.jbnews.com/news/articleView.html?idxno=1325817&fbclid=IwAR0aJvJgP8DFnMtwuSpgOBA6pcJEazv2HAe1UfMitRJpmuo_St1IpH3QvOA",
        "thumbnail": "/contents/images/news/thumb/news_thumb_0015.png",
        "tags": [
            "#TLC Smart Drone",
            "#TLC",
            "#드론영상관제",
            "#충청남도"
        ],
        "writeTime": "2021.02.04 14:50"
    },
    {
        "title": "청양군, 지역 혁신성장 위한 '드론 비행대' 첫 비행 - 충청일보",
        "link": "http://www.ccdailynews.com/news/articleView.html?idxno=2032412&fbclid=IwAR3xijTMFNCG9x-QI7Jg9uERn4rgOuD0AgbDNmvwuABlLfZqMM-VgYBL_3w",
        "thumbnail": "/contents/images/news/thumb/news_thumb_0013.png",
        "tags": [
            "#TLC Smart Drone",
            "#TLC",
            "#T라이브캐스터",
            "#드론영상관제"
        ],
        "writeTime": "2021.01.28 12:00"
    },
    {
        "title": "[신년사] 박정호 SK 텔레콤 CEO “AI기반으로 모든 비즈니스 혁신\"",
        "link": "https://blog.naver.com/efnews/222199808695",
        "thumbnail": "/contents/images/news/thumb/news_thumb_0012.png",
        "tags": [
            "#SK텔레콤",
            "#신년회",
            "#BroadSiteLive",
            "#시청자참여생방송",
            "#언택트방송"
        ],
        "writeTime": "2021.01.07 17:38"
    },
    {
        "title": "'랜선 시무식'...신년 화두는 AI·고객",
        "link": "https://n.news.naver.com/article/009/0004727792?fbclid=IwAR2c67t6PDLYKaoYg6tumF27J08Forq9I3U8CtIVPgEcc4vnhK4xE5w5Ig4",
        "thumbnail": "/contents/images/news/thumb/news_thumb_0011.png",
        "tags": [
            "#SK텔레콤",
            "#신년회",
            "#BroadSiteLive",
            "#시청자참여생방송",
            "#언택트방송"
        ],
        "writeTime": "2021.01.04 18:13"
    },
    {
        "title": "100분 토론 신년특집 - 899회 [LIVE]100분 토론 신년특집...",
        "link": "https://www.youtube.com/watch?fbclid=IwAR1Vap57RM6EqMGt50SFqs5BUGXDNoyPZO_Zhj66OqSdOcHMjZSfY7zQIFA&v=FDkUskv3k08&feature=youtu.be",
        "thumbnail": "/contents/images/news/thumb/news_thumb_0010.png",
        "tags": [
            "#MBC",
            "#100분토론",
            "#BroadSiteLive",
            "#시청자참여생방송",
            "#언택트방송"
        ],
        "writeTime": "2021.01.04 22:00"
    },
    {
        "title": "[온라인 특강]AI 리터러시 교육(고경애)4.0 디지털세상에서...",
        "link": "https://www.youtube.com/watch?fbclid=IwAR1NSna4i1sQvnhELSqYU9ZdFKJDbrWhNc-wwQNH6hQEO752pury8dLM5vQ&v=WbYzHkDJs4A&feature=youtu.be",
        "thumbnail": "/contents/images/news/thumb/news_thumb_0009.png",
        "tags": [
            "#여수MBC",
            "#AI리터러시교육",
            "#BroadSiteLive",
            "#시청자참여생방송",
            "#언택트방송"
        ],
        "writeTime": "2020.12.23 20:00"
    },
    {
        "title": "[MBC 특별생방송]나눔으로 희망을 이어주세요 2",
        "link": "http://playvod.imbc.com/Vod/VodPlay?broadcastId=1000840100156100000&fbclid=IwAR06jDFnN6nVA864rpDVUTPnfvA4invRQdXNZ2FSTPg8QQpkAkdcFCWfwAk",
        "thumbnail": "/contents/images/news/thumb/news_thumb_0008.png",
        "tags": [
            "#MBC특별생방송",
            "#나눔으로희망을이어주세요",
            "#BroadSiteLive",
            "#시청자참여생방송",
            "#언택트방송"
        ],
        "writeTime": "2020.12.24 18:05"
    },
    {
        "title": "한국기후변화연구원 강원 삼척 시에 '마을 지킴이 드론운영...",
        "link": "http://www.newsrep.co.kr/news/articleView.html?idxno=161238",
        "thumbnail": "/contents/images/news/thumb/news_thumb_0007.png",
        "tags": [
            "#TLC Smart Drone",
            "#Drone",
            "#마을지킴이 드론",
            "#TLC Smart Drone",
            "#TLC Smart Drone"
        ],
        "writeTime": "2020.12.16 14:30"
    },
    {
        "title": "'만능해결사'드론 특구 지정 '확실시' 20201208...",
        "link": "https://www.youtube.com/watch?fbclid=IwAR0fe2BJwizoAWvkcRWJxYe62k8yejxlopKmwcrTPx6YsVrvTF45hSgxUlk&v=BKfxrL7ge2E&feature=youtu.be",
        "thumbnail": "/contents/images/news/thumb/news_thumb_0006.png",
        "tags": [
            "#TLCSmartDrone",
            "#TLC",
            "#드론영상관제",
            "#울주군",
            "#드론특구"
        ],
        "writeTime": "2020.12.08 13:17"
    },
    {
        "title": "나눔으로 희망을 이어 주세요. 언텍트 방송",
        "link": "https://www.facebook.com/Tlivecaster.page/photos/a.1901634300163982/2843256869335049/?type=3",
        "thumbnail": "/contents/images/news/thumb/news_thumb_0005.png",
        "tags": [
            "#MBC특별생방송",
            "#나눔으로희망을이어주세요",
            "#BroadSiteLive",
            "#시청자참여생방송",
            "#언택트방송"
        ],
        "writeTime": "2020.12.07 17:52"
    },
    {
        "title": "진천소방서, 재난 현장 관제용 드론 '날갯짓'-충청일보...",
        "link": "http://www.ccdailynews.com/news/articleView.html?idxno=2020901&fbclid=IwAR1x7-0R4WjVJPf7OuwjyHeQ_4IRx5EcNhr93P0De_jrz3NBcp6OLORlyac",
        "thumbnail": "/contents/images/news/thumb/news_thumb_0004.png",
        "tags": [
            "#TLCSmartDrone",
            "#TLC",
            "#충북소방본부",
            "#진천소방서",
            "#드론영상관제"
        ],
        "writeTime": "2020.12.07 13:01"
    },
    {
        "title": "충남도, 공공분야 드론 조종 4개 분야 우수상 ::대전일...",
        "link": "http://www.daejonilbo.com/news/newsitem.asp?pk_no=1450186&fbclid=IwAR3TGiqs0s2qu4Jl3bJTqNXX_dXN0sTILes21fuhNRGBIVKakXbhVNpjB2o",
        "thumbnail": "/contents/images/news/thumb/news_thumb_0003.png",
        "tags": [
            "#TLCSmartDrone",
            "#TLC",
            "#충청남도",
            "#드론영상관제",
            "#드론"
        ],
        "writeTime": "2020.12.06 12:55"
    },
    {
        "title": "충남 공무원들 청양서 드론영상 중계 시스템 점검....",
        "link": "http://www.chungnamilbo.co.kr/news/articleView.html?idxno=573697&fbclid=IwAR0f8w7mpPDVab6Y5UBH9E9XV9oRFh-LY9-7GPoW1olXAoM3uQDU3EiohrY",
        "thumbnail": "/contents/images/news/thumb/news_thumb_0002.png",
        "tags": [
            "#TLC",
            "#TLCSmartDrone",
            "#드론영상관제",
            "#충청남도",
            "#청양군"
        ],
        "writeTime": "2020.11.30 09:42"
    },
    {
        "title": "[LIVE] SKY FREE CITY 도심 항공 교통(UAM)대구 실증...",
        "link": "https://www.youtube.com/watch?fbclid=IwAR3Indw250igS7_8zCMLc8YdQEGdZ20JwSN3qnjnLzxoJEzl2VutB1uM_6c&v=CbDknl-LaUk&feature=youtu.be",
        "thumbnail": "/contents/images/news/thumb/news_thumb_0001.png",
        "tags": [
            "#TLCSmartDrone",
            "#대구수성구",
            "#드론택시",
            "#수상구조",
            "#도심 항공"
        ],
        "writeTime": "2020.11.16 15:00"
    }

]

export default NewsListData