
// import 'reflect-metadata';
import {Component, InjectReactive, Prop, Vue} from 'vue-property-decorator';
import {mixins} from "vue-class-component";
import ComponentBase from "@/components/ComponentBase";

@Component({
    name: 'ProductIntro'
})
export default class ProductIntro extends mixins(ComponentBase) {
  @InjectReactive() isMobile!: boolean;
}
