





















































































































































































































import {Component, InjectReactive, Prop, Vue} from 'vue-property-decorator';
import {mixins} from "vue-class-component";
import ComponentBase from "@/components/ComponentBase";
import InquiriesBanner from "@/components/InquiriesBanner/InquiriesBanner.vue";

@Component({
  name: 'ProductVideoServer',
  components: {InquiriesBanner}

})
export default class ProductVideoServer extends mixins(ComponentBase) {
  @InjectReactive() isMobile!: boolean;

  deviceNavList = ['영상 수신 서버', '영상 중계 서버']

  deviceIndex = 0

  deviceClick(index: number) {
    this.deviceIndex = index
  }

}
