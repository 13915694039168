





















































































































































































































































// import 'reflect-metadata';
import {Component, Inject, InjectReactive, Prop, Vue, Watch, ProvideReactive} from 'vue-property-decorator';
import {mixins} from "vue-class-component";
import ComponentBase from "@/components/ComponentBase";
import HeaderMenu from "@/components/Header/HeaderMenu.vue";

@Component({
  name: 'Header',
  components: {HeaderMenu}
})
export default class Header extends mixins(ComponentBase) {
  @InjectReactive() scrollTop!: number;
  @InjectReactive() isMobile!: boolean;

  openMenu = false

  toggleOpenMen(){
    this.openMenu = !this.openMenu
  }
  mounted() {
    this.initMenuScript();
  }

  @Watch('isMobile')
  onIsMobileChange() {
    setTimeout(() => {
      this.initMenuScript();
    }, 500);
  }


  initMenuScript() {
    // @ts-ignore
    const $ = window.$;
    // @ts-ignore
    // $(".gnb_sub").each(function(){
    //   // @ts-ignore
    //   $(this).css({
    //     // @ts-ignore
    //     height:$(this).outerHeight()
    //   });
    // });


    if (!this.isMobile) {
      /* gnb */
      // @ts-ignore
      $(".gnb_list > li:has(ul)").children("a").on("mouseover", function (e) {
        $(".header").addClass("open");
        $(".gnb_list > li").removeClass("on");
        // @ts-ignore
        $(this).parents("li").addClass("on");

        $(".header").css("height", $(".gnb_list > .on > .gnb_sub").outerHeight() + 120);
      });

      // @ts-ignore
      $(".gnb_list > li:not(:has(ul))").children("a").on("mouseover", function (e) {
        $(".header").addClass("open");
        $(".gnb_list > li").removeClass("on");
        // @ts-ignore
        $(this).parents("li").addClass("on");

        //$(".header").css("height", "80px");
      });

      // @ts-ignore
      $(".contact").children("a").on("mouseover", function (e) {
        $(".header").addClass("open");
        $(".gnb_list > li").removeClass("on");
        // @ts-ignore
        $(this).parents("li").addClass("on");

        //$(".header").css("height", "80px");
      });
    }
    //else {
    //  // mobile
    //  /* 모바일 gnb */
    //  $(".btn_gnb").on("click", function () {
    //    $(".m_header").toggleClass("open");
    //    // @ts-ignore
    //    $(this).toggleClass("close");
    //  });
    //
    //  // @ts-ignore
    //  $(".m_gnb_list > li > a").on("click", function (e) {
    //    $(".m_gnb_list > li > a").removeClass("on");
    //    // @ts-ignore
    //    $(this).addClass("on");
    //  });
    //
    //  // 하위 메뉴 클릭시 메뉴창 닫기
    //  // @ts-ignore
    //  $(".m_gnb_list > li > ul > li > a").on("click", function (e) {
    //    // @ts-ignore
    //    const $ = window.$;
    //    $(".m_header").removeClass("open");
    //  });
    //
    //  // @ts-ignore
    //  $(".m_gnb_list > li:has(ul)").children("a").on("click", function (e) {
    //    e.preventDefault();
    //  });
    //
    //  // 하위 메뉴 없는 상위 메뉴 클릭시 메뉴창 닫기
    //  // @ts-ignore
    //  $(".m_gnb_list > li:not(:has(ul))").children("a").on("click", function (e) {
    //    // @ts-ignore
    //    const $ = window.$;
    //    $(".m_header").removeClass("open");
    //  });
    //  /* //모바일 gnb */
    //
    //  $(".m_header").css("height", "113px");
    //}
  }

  onMouseLeaveFromHeader() {
    // @ts-ignore
    const $ = window.$;
    $(".gnb_list li").removeClass("on");
    $(".header").removeClass("open");
    $(".header").css("height", "80px");
  }

  @Watch('$route')
  onRouteChange() {
    if (!this.isMobile) {
      this.onMouseLeaveFromHeader();
    }
  }
}
