
import { Component, Prop, Vue } from 'vue-property-decorator';
import {mixins} from "vue-class-component";
import ComponentBase from "@/components/ComponentBase";

@Component({
    name: 'ProductBroadsiteLive'
})
export default class ProductBroadsiteLive extends mixins(ComponentBase) {

}
