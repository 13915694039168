
import {Component, Prop, Provide, ProvideReactive, Vue} from 'vue-property-decorator';
import Header from "@/components/Header/Header.vue";
import Footer from "@/components/Footer/Footer.vue";
import {mixins} from "vue-class-component";
import ComponentBase from "@/components/ComponentBase";

@Component({
  name: 'HeadWrapper',
  components: {Header, Footer},
  inject: []
})
export default class HeadWrapper extends mixins(ComponentBase) {
  @ProvideReactive() scrollTop = 0;
  @ProvideReactive() isMobile = false;

  created() {
    this.onWindowSizeChanged();
  }

  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    window.addEventListener("resize", this.onWindowSizeChanged);
  }

  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll() {
    this.scrollTop = window.scrollY || window.pageYOffset || document.documentElement.scrollTop;
  }

  onWindowSizeChanged() {
    const windowWidth = window.innerWidth;
    if(windowWidth < 760) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
  }
}
