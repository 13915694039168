import Vue from 'vue'
import VueRouter, {RouteConfig} from 'vue-router'
import Main from "@/views/Main/Main.vue";
import ProductIntro from "@/views/Product/ProductIntro/ProductIntro.vue";
import HeadWrapper from "@/views/HeadWrapper/HeadWrapper.vue";
import ProductTlcSmart from "@/views/Product/ProductTlcSmart/ProductTlcSmart.vue";
import ProductTlcSmartDrone from "@/views/Product/ProductTlcSmartDrone/ProductTlcSmartDrone.vue";
import ProductTlcSmartDronePreview from "@/views/Product/ProductTlcSmartDronePreview/ProductTlcSmartDronePreview.vue";
import ProductTlcAirView from "@/views/Product/ProductTlcAirView/ProductTlcAirView.vue";
import ProductGcsCar from "@/views/Product/ProductGcsCar/ProductGcsCar.vue";
import ProductTlcIpConverter from "@/views/Product/ProductTlcIpConverter/ProductTlcIpConverter.vue";
import ProductTLiveCaster from "@/views/Product/ProductTLiveCaster/ProductTLiveCaster.vue";
import ProductTLiveStudio from "@/views/Product/ProductTLiveStudio/ProductTLiveStudio.vue";
import ProductTlcSmartInterview from "@/views/Product/ProductTlcSmartInterview/ProductTlcSmartInterview.vue";
import ProductBroadsiteLive from "@/views/Product/ProductBroadsiteLive/ProductBroadsiteLive.vue";
import ProductBroadsiteTalk from "@/views/Product/ProductBroadsiteTalk/ProductBroadsiteTalk.vue";
import ProductOverview from "@/views/Product/ProductOverview/ProductOverview.vue";
import ProductDevice from "@/views/Product/ProductDevice/ProductDevice.vue";
import ProductVideoServer from "@/views/Product/ProductVideoServer/ProductVideoServer.vue";
import ProductVideoMonitoring from "@/views/Product/ProductVideoMonitoring/ProductVideoMonitoring.vue";
import TechMultipathAggregation from "@/views/Technology/TechMultipathAggregation/TechMultipathAggregation.vue";
import TechAdaptiveUplinkStreaming
    from "@/views/Technology/TechAdaptiveUplinkStreaming/TechAdaptiveUplinkStreaming.vue";
import TechVariableUplinkOptions from "@/views/Technology/TechVariableUplinkOptions/TechVariableUplinkOptions.vue";
import TechScalability from "@/views/Technology/TechScalability/TechScalability.vue";
import TechFaq from "@/views/Technology/TechFaq/TechFaq.vue";
import SolutionIntro from "@/views/Solution/SolutionIntro/SolutionIntro.vue";
import SolutionMobileSurveillance from "@/views/Solution/SolutionMobileSurveillance/SolutionMobileSurveillance.vue";
import AppConstructionSiteMonitoring
    from "@/views/Solution/AppConstructionSiteMonitoring/AppConstructionSiteMonitoring.vue";
import DownloadMain from "@/views/Download/DownloadMain/DownloadMain.vue";
import ProductTlcLandView from "@/views/Product/ProductTlcLandView/ProductTlcLandView.vue";
import SolutionDroneSurveillance from "@/views/Solution/SolutionDroneSurveillance/SolutionDroneSurveillance.vue";
import AppLiveOutdoorBroadcasting from "@/views/Solution/AppLiveOutdoorBroadcasting/AppLiveOutdoorBroadcasting.vue";
import ServiceBroadsiteLive from "@/views/Service/ServiceBroadsiteLive/ServiceBroadsiteLive.vue";
import NewsList from "@/views/News/NewsList/NewsList.vue";
import ContactMain from "@/views/Contact/ContactMain/ContactMain.vue";
import TechIntro from "@/views/Technology/TechIntro/TechIntro.vue";
import ServiceTLiveCasterPlan from "@/views/Service/ServiceTLiveCasterPlan/ServiceTLiveCasterPlan.vue";
import PolicyWrapper from "@/views/Policy/PolicyWrapper/PolicyWrapper.vue";
import AppAutonomousCarShip from "@/views/Solution/AppAutonomousCarShip/AppAutonomousCarShip.vue";
import AppAutonomousMobileRobot from "@/views/Solution/AppAutonomousMobileRobot/AppAutonomousMobileRobot.vue";
import TechStreamingProtocol from "@/views/Technology/TechStreamingProtocol/TechStreamingProtocol.vue";
import SolutionRobotSurveillance from "@/views/Solution/SolutionRobotSurveillance/SolutionRobotSurveillance.vue";
import SolutionLiveBoardCastSurveillance
    from "@/views/Solution/SolutionLiveBoardcastSurveillance/SolutionLiveBoardCastSurveillance.vue";
import AboutIntro from "@/views/About/AboutIntro/AboutIntro.vue";
import AboutEncryption from "@/views/About/AboutEncryption/AboutEncryption.vue";
import AboutStreamingProtocol from "@/views/About/AboutStreamingProtocol/AboutStreamingProtocol.vue";
import AboutMultipathAggregation from "@/views/About/AboutMultipathAggregation/AboutMultipathAggregation.vue";
import AboutAdaptiveUplinkStreaming from "@/views/About/AboutAdaptiveUplinkStreaming/AboutAdaptiveUplinkStreaming.vue";
Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
    {
        path: '/',
        component: HeadWrapper,
        children: [
            {
                path: '',
                name: 'Home',
                component: Main
            },
        ]
    },
    {
        path: '/product/',
        component: HeadWrapper,
        children: [
            {
                path: 'intro',
                name: 'ProductIntro',
                component: ProductIntro
            },
            {
                path: 'tlc_smart',
                name: 'ProductTlcSmart',
                component: ProductTlcSmart
            },
            {
                path: 'tlc_smart_drone',
                name: 'ProductTlcSmartDrone',
                component: ProductTlcSmartDrone
            },
            {
                path: 'tlc_smart_drone_preview',
                name: 'ProductTlcSmartDronePreview',
                component: ProductTlcSmartDronePreview
            },
            {
                path: 'tlc_air_view',
                name: 'ProductTlcAirView',
                component: ProductTlcAirView
            },
            {
                path: 'tlc_land_view',
                name: 'ProductTlcLandView',
                component: ProductTlcLandView
            },
            {
                path: 'gcs_car',
                name: 'ProductGcsCar',
                component: ProductGcsCar
            },
            {
                path: 'tlc_ip_converter',
                name: 'ProductTlcIpConverter',
                component: ProductTlcIpConverter
            },
            {
                path: 't_live_caster',
                name: 'ProductTLiveCaster',
                component: ProductTLiveCaster
            },
            {
                path: 't_live_studio',
                name: 'ProductTLiveStudio',
                component: ProductTLiveStudio
            },
            {
                path: 'tlc_smart_interview',
                name: 'ProductTlcSmartInterview',
                component: ProductTlcSmartInterview
            },
            {
                path: 'broadsite_live',
                name: 'ProductBroadsiteLive',
                component: ProductBroadsiteLive
            },
            {
                path: 'broadsite_talk',
                name: 'ProductBroadsiteTalk',
                component: ProductBroadsiteTalk
            },
            {
                path: 'overview',
                name: 'ProductOverview',
                component: ProductOverview
            },
            {
                path: 'device',
                name: 'ProductDevice',
                component: ProductDevice
            },
            {
                path: 'video_server',
                name: 'ProductVideoServer',
                component: ProductVideoServer
            },
            {
                path: 'video_monitoring',
                name: 'ProductVideoMonitoring',
                component: ProductVideoMonitoring,
            }
        ]
    },
    {
        path: '/service/',
        component: HeadWrapper,
        children: [
            {
                path: 't_live_caster_plan',
                name: 'ServiceTLiveCasterPlan',
                component: ServiceTLiveCasterPlan
            },
            {
                path: 'broadsite_live',
                name: 'ServiceBroadsiteLive',
                component: ServiceBroadsiteLive
            }
        ]
    },
    {
        path: '/tech/',
        component: HeadWrapper,
        children: [
            {
                path: 'intro',
                name: 'AboutIntro',
                component: AboutIntro
            },
            {
                path: 'streaming_protocol',
                name: 'TechStreamingProtocol',
                component: TechStreamingProtocol
            },
            {
                path: 'multipath_aggregation',
                name: 'TechMultipathAggregation',
                component: TechMultipathAggregation
            },
            {
                path: 'adaptive_uplink_streaming',
                name: 'TechAdaptiveUplinkStreaming',
                component: TechAdaptiveUplinkStreaming
            },
            {
                path: 'variable_uplink_options',
                name: 'TechVariableUplinkOptions',
                component: TechVariableUplinkOptions
            },
            {
                path: 'scalability',
                name: 'TechScalability',
                component: TechScalability
            },
            {
                path: 'faq',
                name: 'TechFaq',
                component: TechFaq
            },
            {
                path: 'encryption',
                name: 'AboutEncryption',
                component: AboutEncryption
            },
            {
                path: 'about_streaming_protocol',
                name: 'AboutStreamingProtocol',
                component: AboutStreamingProtocol
            },
            {
                path: 'about_multipath_aggregation',
                name: 'AboutMultipathAggregation',
                component: AboutMultipathAggregation
            },
            {
                path: 'about_adaptive_uplink_streaming',
                name: 'AboutAdaptiveUplinkStreaming',
                component: AboutAdaptiveUplinkStreaming
            }
        ]
    },
    {
        path: '/app/',
        component: HeadWrapper,
        children: [
            {
                path: 'intro',
                name: 'AppIntro',
                component: SolutionIntro
            },
            {
                path: 'mobile_surveillance',
                name: 'AppMobileSurveillance',
                component: SolutionMobileSurveillance
            },
            {
                path: 'construction_site_monitoring',
                name: 'AppConstructionSiteMonitoring',
                component: AppConstructionSiteMonitoring
            },
            {
                path: 'drone_surveillance_system',
                name: 'AppDroneSurveillanceSystem',
                component: SolutionDroneSurveillance
            },
            {
                path: 'live_outdoor_broadcasting',
                name: 'AppLiveOutdoorBroadcasting',
                component: AppLiveOutdoorBroadcasting
            },
            {
                path: 'autonomous_car_ship',
                name: 'AppAutonomousCarShip',
                component: AppAutonomousCarShip
            },
            {
                path: 'autonomous_mobile_robot',
                name: 'AppAutonomousMobileRobot',
                component: AppAutonomousMobileRobot
            },
            {
                path: 'robot_surveillance_system',
                name: 'AppRobotSurveillanceSystem',
                component: SolutionRobotSurveillance
            },
            {
                path: 'live_boardcast_surveillance_system',
                name: 'AppLiveBoardcasterSurveillanceSystem',
                component: SolutionLiveBoardCastSurveillance
            }
        ]
    },
    {
        path: '/solution/',
        component: HeadWrapper,
        children: [
            {
                path: 'intro',
                name: 'SolutionIntro',
                component: SolutionIntro
            },
            {
                path: 'mobile_surveillance',
                name: 'SolutionMobileSurveillance',
                component: SolutionMobileSurveillance
            },
            {
                path: 'drone_surveillance',
                name: 'SolutionDroneSurveillance',
                component: SolutionDroneSurveillance
            },
            {
                path: 'robot_surveillance',
                name: 'SolutionRobotSurveillance',
                component: SolutionRobotSurveillance
            },
            {
                path: 'live_boardcast_surveillance',
                name: 'SolutionLiveBoardCastSurveillance',
                component: SolutionLiveBoardCastSurveillance
            }
        ]
    },
    {
        path: '/download',
        component: HeadWrapper,
        children: [
            {
                path: '',
                name: 'DownloadMain',
                component: DownloadMain
            },
        ]
    },
    {
        path: '/news',
        component: HeadWrapper,
        children: [
            {
                path: '',
                name: 'NewsList',
                component: NewsList
            },
        ]
    },
    {
        path: '/contact',
        component: HeadWrapper,
        children: [
            {
                path: '',
                name: 'ContactMain',
                component: ContactMain
            },
        ]
    },
    {
        path: '/policy/:tab/:page',
        component: PolicyWrapper,
    },

    // {
    //   path: '/about',
    //   name: 'About',
    //   // route level code-splitting
    //   // this generates a separate chunk (about.[hash].js) for this route
    //   // which is lazy-loaded when the route is visited.
    //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    // }
]

const router = new VueRouter({
    routes,
    scrollBehavior(to, from, savedPosition) {
        return {x: 0, y: 0}
    }
})

export default router
