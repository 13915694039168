












































































































































































































































































































































import {Component, InjectReactive, Prop, Vue} from 'vue-property-decorator';
import {mixins} from "vue-class-component";
import ComponentBase from "@/components/ComponentBase";
import SolutionLink from "@/components/SolutionLink.vue";
@Component({
  name: 'SolutionDroneSurveillance', components: {SolutionLink}
})
export default class SolutionDroneSurveillance extends mixins(ComponentBase) {
  @InjectReactive() isMobile!: boolean;

  mounted() {
    if (this.$route.matched[0].path === '/solution')
      console.log('true')
    else console.log('false')
  }
}
