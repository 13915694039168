

































































































































































































































import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
    name: 'PlanPrivacyPolicyContent_Old2'
})
export default class PlanPrivacyPolicyContent_Old2 extends Vue {

}
