import Vue from "vue";
import Component from "vue-class-component";
import LanguageController from "@/components/LanguageController";

@Component
export default class ComponentBase extends Vue {
  applyLink = 'https://plan.tlivecaster.com';
  lang = LanguageController;

  setLanguage(newLang: string) {
    this.lang.setLanguage(newLang);
  }

  get ko() {
    return this.lang.ko;
  }
  get en() {
    return this.lang.en;
  }
}
