







// import 'reflect-metadata';
import {Component, InjectReactive, Prop} from 'vue-property-decorator';
import {mixins} from "vue-class-component";
import ComponentBase from "@/components/ComponentBase";
import UseInquiriesButton from "@/components/UseInquiriesButton/UseInquiriesButton.vue";

@Component({
  name: 'AboutBanner', components: {UseInquiriesButton}
})
export default class AboutBanner extends mixins(ComponentBase) {
  @InjectReactive() isMobile!: boolean;
}
