import { render, staticRenderFns } from "./HeadWrapper.html?vue&type=template&id=3c2770ea&scoped=true&external"
import script from "./HeadWrapper.vue?vue&type=script&lang=ts"
export * from "./HeadWrapper.vue?vue&type=script&lang=ts"
import style0 from "./HeadWrapper.scss?vue&type=style&index=0&id=3c2770ea&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3c2770ea",
  null
  
)

export default component.exports