





















































































































































































































































































































































import {Component, InjectReactive, Prop, Vue} from 'vue-property-decorator';
import {mixins} from "vue-class-component";
import ComponentBase from "@/components/ComponentBase";
import SolutionLink from "@/components/SolutionLink.vue";

@Component({
  name: 'SolutionMobileSurveillance',
  components: {SolutionLink}
})
export default class SolutionMobileSurveillance extends mixins(ComponentBase) {
  @InjectReactive() isMobile!: boolean;
}
