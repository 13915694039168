



































































































































import {Component, InjectReactive, Prop, Vue} from 'vue-property-decorator';
import {mixins} from "vue-class-component";
import ComponentBase from "@/components/ComponentBase";
import InquiriesBanner from "@/components/InquiriesBanner/InquiriesBanner.vue";
import BusinessButton from "@/components/BusinessButton/BusinessButton.vue";
@Component({
  name: 'SolutionIntro',
  components: {InquiriesBanner, BusinessButton}
})
export default class SolutionIntro extends mixins(ComponentBase) {
  @InjectReactive() isMobile!: boolean;

}
