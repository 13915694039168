import { render, staticRenderFns } from "./ProductOverview.vue?vue&type=template&id=f870d50c&scoped=true"
import script from "./ProductOverview.vue?vue&type=script&lang=ts"
export * from "./ProductOverview.vue?vue&type=script&lang=ts"
import style0 from "./ProductOverview.vue?vue&type=style&index=0&id=f870d50c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f870d50c",
  null
  
)

export default component.exports