







































































































































































































































































import {Component, InjectReactive, Prop, Ref, Vue} from 'vue-property-decorator';
import {mixins} from "vue-class-component";
import ComponentBase from "@/components/ComponentBase";
import InquiriesBanner from "@/components/InquiriesBanner/InquiriesBanner.vue";

@Component({
  name: 'ProductDevice',
  components: {InquiriesBanner}
})
export default class ProductDevice extends mixins(ComponentBase) {
  @InjectReactive() isMobile!: boolean;
  @Ref() test!: HTMLDivElement
  deviceNavList = ['MOBILE', 'DRONE', 'ROBOTICS', 'IP CCTV', 'CAMERAS']

  deviceNavIndex = 0

  deviceNavClick(index: number) {
    this.deviceNavIndex = index
  }

  scrollTop(className: any) {
    window.scrollTo({top: (className.offsetTop - 80), left: 0, behavior: "smooth"})
  }

}
