import { render, staticRenderFns } from "./ProductIntro.html?vue&type=template&id=d035e56a&scoped=true&external"
import script from "./ProductIntro.vue?vue&type=script&lang=ts"
export * from "./ProductIntro.vue?vue&type=script&lang=ts"
import style0 from "./ProductIntro.scss?vue&type=style&index=0&id=d035e56a&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d035e56a",
  null
  
)

export default component.exports