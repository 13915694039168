
import {Component, InjectReactive, Prop, Vue, Watch} from 'vue-property-decorator';
import {mixins} from "vue-class-component";
import ComponentBase from "@/components/ComponentBase";

@Component({
    name: 'ServiceTLiveCasterPlan'
})
export default class ServiceTLiveCasterPlan extends mixins(ComponentBase) {
  @InjectReactive() scrollTop!: number;
  @InjectReactive() isMobile!: boolean;


  @Watch('scrollTop')
  onScrollTopChange() {
    // @ts-ignore
    const $ = window.$;
    $(".box").each(function() {
      // @ts-ignore
      if($(window).scrollTop() >= $(this).offset().top - 800) {
        // @ts-ignore
        $(this).addClass("show");
      } else {
        // @ts-ignore
        $(this).removeClass("show");
      }
    });

    $(".ip_convert_img").each(function() {
      // @ts-ignore
      if($(window).scrollTop() >= $(this).offset().top - 800) {
        // @ts-ignore
        $(this).addClass("show");
      } else {
        // @ts-ignore
        $(this).removeClass("show");
      }
    });
  }
}
