
import {Component, InjectReactive, Prop, Vue, Watch} from 'vue-property-decorator';
import {mixins} from "vue-class-component";
import ComponentBase from "@/components/ComponentBase";
import ServerRequesting from "@/components/ServerRequesting/ServerRequesting.vue";

@Component({

  name: 'DownloadMain',
  components: {ServerRequesting}
})
export default class DownloadMain extends mixins(ComponentBase) {
  @InjectReactive() isMobile!: boolean;

  name = '';
  email = '';
  phone = '';
  company = '';

  over14 = false;
  privacyAgree = false;

  downloadFormOpened = false;

  requesting = false;

  downloadApp() {
    if(this.requesting) {
      alert('요청중입니다. 잠시만 기다려 주세요.');
      return;
    }

    if(this.name.trim() === '') {
      alert('이름을 입력해 주세요.');
      return;
    }
    if(this.email.trim() === '') {
      alert('이메일을 입력해 주세요.');
      return;
    }
    if(this.phone.trim() === '') {
      alert('전화번호를 입력해 주세요.');
      return;
    }

    if(! this.over14) {
      alert('만 14세 이상에 체크해주세요.');
      return;
    }
    if(! this.privacyAgree) {
      alert('개인정보 수집/이용 에 동의하셔야 합니다.');
      return;
    }

    this.requesting = true;

    //
    // @ts-ignore
    const $ = window.$;
    $.post('/app_download_request.php', {name: this.name, email: this.email, phone: this.phone, company: this.company}, (res: any) => {
      this.requesting = false;
      console.log(res);
      if(res === 'ok') {
        this.downloadFormOpened = false;

        window.open('/TLCSmartDronePreview.apk');
      } else {
        alert('메일을 보낼 수 없습니다.');
      }
    });
  }

  @Watch('downloadFormOpened')
  onDownloadFormOpenedChange() {
    if(this.downloadFormOpened) {
      // @ts-ignore
      document.body.style = 'overflow: hidden;';
    } else {
      // @ts-ignore
      document.body.style = 'overflow: ;';
    }
  }

  openAppDownload() {
    if(/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
      alert('iOS 는 지원되지 않습니다.');
      return;
    }
    this.downloadFormOpened = true;
  }
}
