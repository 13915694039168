
import 'reflect-metadata';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
    name: 'Agreement'
})
export default class Agreement extends Vue {

}
