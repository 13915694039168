



// import 'reflect-metadata';
import {Component, InjectReactive} from 'vue-property-decorator';
import {mixins} from "vue-class-component";
import ComponentBase from "@/components/ComponentBase";

@Component({
  name: 'BusinessButton',
})
export default class BusinessButton extends mixins(ComponentBase) {
  @InjectReactive() isMobile!: boolean;
}
