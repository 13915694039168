



// import 'reflect-metadata';
import {Component, InjectReactive, Prop} from 'vue-property-decorator';
import {mixins} from "vue-class-component";
import ComponentBase from "@/components/ComponentBase";

@Component({
  name: 'UseInquiriesButton'
})
export default class UseInquiriesButton extends mixins(ComponentBase) {
  @InjectReactive() isMobile!: boolean;
  @Prop() name !: string
  @Prop() mobile !: boolean

}
