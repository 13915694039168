









































































































































































































































import {Component, InjectReactive, Prop, Vue, Watch} from 'vue-property-decorator';
import {mixins} from "vue-class-component";
import ComponentBase from "@/components/ComponentBase";
import InquiriesBanner from "@/components/InquiriesBanner/InquiriesBanner.vue";

@Component({
  name: 'ProductVideoServer',
  components: {InquiriesBanner}
})
export default class ProductVideoServer extends mixins(ComponentBase) {
  @InjectReactive() isMobile!: boolean;

  deviceNavList = ['원격 관제', '영상 모니터링']

  deviceIndex = 0

  deviceClick(index: number) {
    this.deviceIndex = index
  }

  scrollTop(className: any) {
    window.scrollTo({top: (className.offsetTop - 80), left: 0, behavior: "smooth"})
  }


}
