
import 'reflect-metadata';
import { Component, Prop, Vue } from 'vue-property-decorator';
import Footer from "@/components/Footer/Footer.vue";
import PolicyPrivacy from "@/views/Policy/TLiveCaster/PolicyPrivacy/PolicyPrivacy.vue";
import Terms from "@/views/Policy/TLiveCaster/Terms/Terms.vue";
import Agreement from "@/views/Policy/TLiveCaster/Agreement/Agreement.vue";
import PlanPrivacyPolicy from "@/views/Policy/TLivecasterPlan/PlanPrivacyPolicy/PlanPrivacyPolicy.vue";
import PlanTerms from "@/views/Policy/TLivecasterPlan/PlanTerms/PlanTerms.vue";
import PlanAgreement from "@/views/Policy/TLivecasterPlan/PlanAgreement/PlanAgreement.vue";

@Component({
  name: 'PolicyWrapper',
  components: {Footer, PolicyPrivacy, Terms, Agreement, PlanPrivacyPolicy, PlanTerms, PlanAgreement}
})
export default class PolicyWrapper extends Vue {

  created() {
    console.log(this.$route);
  }

  get page() {
    return this.$route.params.page;
  }
  get tab() {
    return this.$route.params.tab;
  }

  goMain() {
    this.$router.push({path: '/'});
  }
}
