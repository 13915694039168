
import { Component, Prop, Vue } from 'vue-property-decorator';
import {mixins} from "vue-class-component";
import ComponentBase from "@/components/ComponentBase";

@Component({
    name: 'ProductGcsCar'
})
export default class ProductGcsCar extends mixins(ComponentBase) {

}
