






























import 'reflect-metadata';
import { Component, Prop, Vue } from 'vue-property-decorator';
import PlanPrivacyPolicyContent_Latest from "@/views/Policy/TLivecasterPlan/PlanPrivacyPolicy/PlanPrivacyPolicyContent_Latest.vue";
import PlanPrivacyPolicyContent_Old2 from "@/views/Policy/TLivecasterPlan/PlanPrivacyPolicy/PlanPrivacyPolicyContent_Old2.vue";
import PlanPrivacyPolicyContent_Old1 from "@/views/Policy/TLivecasterPlan/PlanPrivacyPolicy/PlanPrivacyPolicyContent_Old1.vue";

@Component({
    name: 'PlanPrivacyPolicy',
  components: {PlanPrivacyPolicyContent_Old1,PlanPrivacyPolicyContent_Old2, PlanPrivacyPolicyContent_Latest}
})
export default class PlanPrivacyPolicy extends Vue {
  version = 'latest'
}
