












































































































































































































































































































































import {Component, InjectReactive, Prop, Vue} from 'vue-property-decorator';
import {mixins} from "vue-class-component";
import ComponentBase from "@/components/ComponentBase";
import SolutionLink from "@/components/SolutionLink.vue";
@Component({
  name: 'SolutionLiveBoardCastSurveillance', components: {SolutionLink}
})
export default class SolutionLiveBoardCastSurveillance extends mixins(ComponentBase) {
  @InjectReactive() isMobile!: boolean;

}
