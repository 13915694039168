








































































import {Component, Prop, Emit, ProvideReactive, Ref, Vue, Mixins} from "vue-property-decorator";
import ComponentBase from "@/components/ComponentBase";
import {mixins} from "vue-class-component";
@Component({ name: "HeaderMenu",  components: {}})
export default class HeaderMenu extends mixins(ComponentBase) {
  @Ref() refHeaderMenuBg!: HTMLElement
  @Prop() openMenu !: boolean
  @Emit('close')
  close(){
  /**/
}

}
