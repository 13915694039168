class LanguageController {
  ko = navigator.language.indexOf('ko') >= 0;
  en = navigator.language.indexOf('ko') < 0;

  constructor() {
    const lang = localStorage.getItem('language');
    if(lang !== null) {
      this.ko = lang === 'ko';
      this.en = lang !== 'ko';
    }

    this.applyFont();
  }

  setLanguage(newLang: string) {
    this.ko = newLang === 'ko';
    this.en = newLang !== 'ko';
    localStorage.setItem('language', newLang);

    this.applyFont();
  }

  applyFont() {
    const app = document.getElementById('app');
    if(app) {
      if(this.en) {
        app.style.fontFamily = 'Roboto';
      } else {
        app.style.fontFamily = '';
      }
    }
  }
}

export default new LanguageController();
